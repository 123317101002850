import { Asset } from '@waves/data-entities';

import { type AssetDetailsItem } from './types';

export function createAssetFromDetails(asset: AssetDetailsItem) {
  return new Asset({
    description: asset.description,
    hasScript: asset.scripted,
    height: asset.issueHeight,
    id: asset.assetId,
    minSponsoredFee: asset.minSponsoredAssetFee ?? undefined,
    name: asset.name,
    precision: asset.decimals,
    quantity: asset.quantity,
    reissuable: asset.reissuable,
    sender: asset.issuer,
    timestamp: new Date(asset.issueTimestamp),
  });
}
