import { type KeeperAssetInfoResponseItem } from '_core/keeperApi';
import { BigNumber } from '@waves/bignumber';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { QrCodeIcon } from 'icons/qrCode';
import { isErrorLike } from 'modules/errors/utils';
import {
  type DepositAddressesResponse,
  type DepositCurrency,
  type PlatformsItem,
} from 'modules/gateways/api';
import { useNotifications } from 'modules/notifications/context';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import { Button } from 'shared/components/Button';

import { metamaskPlatformIds } from './constants';
import * as styles from './form.module.css';
import { QrModal } from './qrModal';

function uniq<T>(array: T[]) {
  return Array.from(new Set(array));
}

interface Props {
  currencyId: string | null;
  depositAddresses: DepositAddressesResponse | null;
  depositCurrencies: DepositCurrency[];
  keeperAssetInfo: KeeperAssetInfoResponseItem[] | null;
  platformId: string | null;
  platforms: PlatformsItem[];
  userAddress: string;
  onCurrencyChange: (newCurrencyId: string | null) => void;
  onPlatformChange: (newPlatformId: string | null) => void;
  onReset: () => void;
}

export function TopUpForm({
  currencyId,
  depositAddresses,
  depositCurrencies,
  keeperAssetInfo,
  platformId,
  platforms,
  userAddress,
  onCurrencyChange,
  onPlatformChange,
  onReset,
}: Props) {
  const notifications = useNotifications();

  const [copiedNotification, setCopiedNotification] = useState(false);
  const [qrModalShow, setQrModalShow] = useState(false);

  const [topUpAmountMasked, setTopUpAmountMasked] = useState('');
  const [topUpAmount, setTopUpAmount] = useState('');

  useEffect(() => {
    setTopUpAmount('');
    setTopUpAmountMasked('');
  }, [currencyId]);

  const platformsRecord = Object.fromEntries(
    platforms.map(platform => [platform.id, platform]),
  );

  const platform = platformId ? platformsRecord[platformId] : null;

  const assetInfo =
    depositAddresses &&
    keeperAssetInfo?.find(
      info => info.id === depositAddresses.currency.waves_asset_id,
    );

  return (
    <Row>
      <Col sm={6}>
        <Form className={styles.box} onReset={onReset}>
          <div className={styles.formHead}>
            <span>Fill the required fields</span>

            <button className={styles.resetButton} type="reset">
              Reset
            </button>
          </div>

          <Stack gap={3}>
            <Form.Group controlId="userAddress">
              <Form.Label className={styles.formLabel}>Top-up</Form.Label>

              <Form.Control
                className={styles.formControl}
                readOnly
                value={userAddress}
              />
            </Form.Group>

            <Form.Group controlId="asset">
              <Form.Label className={styles.formLabel}>With</Form.Label>

              <Form.Select
                className={styles.formControl}
                value={currencyId ?? ''}
                onChange={event => {
                  onCurrencyChange(event.currentTarget.value || null);
                }}
              >
                <option value="">Select token</option>

                {uniq(depositCurrencies.map(c => c.id))
                  .sort()
                  .map(id => (
                    <option key={id} value={id}>
                      {id}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="platform">
              <Form.Label className={styles.formLabel}>Network</Form.Label>

              <Form.Select
                className={styles.formControl}
                disabled={currencyId == null}
                value={platformId ?? ''}
                onChange={event => {
                  onPlatformChange(event.currentTarget.value || null);
                }}
              >
                <option value="">Select network</option>

                {currencyId &&
                  uniq(
                    depositCurrencies
                      .filter(c => c.id === currencyId)
                      .map(c => c.platform_id),
                  )
                    .sort()
                    .map(id => (
                      <option key={id} value={id}>
                        {platformsRecord[id].name}
                      </option>
                    ))}
              </Form.Select>
            </Form.Group>
          </Stack>

          <div className={styles.formFootnote}>
            The crypto-gateways are operated by Waves.Exchange. Waves Domains
            does not undertake any responsibility for the gateway services. For
            details, see{' '}
            <a
              href="https://waves.exchange/deposit_withdraw/faq"
              rel="noopener noreferrer"
              target="_blank"
            >
              WX.Network FAQ
            </a>{' '}
            and contact their{' '}
            <a
              href="https://support.waves.exchange/"
              rel="noopener
                    noreferrer"
              target="_blank"
            >
              support
            </a>
            .
          </div>
        </Form>
      </Col>

      <Col sm={6}>
        {currencyId && platform && (
          <div className={clsx(styles.box, styles.gatewayInfo)}>
            {depositAddresses == null ? (
              <div className={styles.gatewayInfoSpinnerWrapper}>
                <Spinner />
              </div>
            ) : (
              <>
                <label className={styles.formHead} htmlFor="gatewayAddress">
                  Send {depositAddresses.currency.id} to {platform.name} address
                  below
                </label>

                <InputGroup>
                  <Form.Control
                    className={styles.formControl}
                    id="gatewayAddress"
                    readOnly
                    value={depositAddresses.deposit_addresses[0]}
                  />

                  <Button
                    variant="outline-primary"
                    onClick={() => setQrModalShow(true)}
                  >
                    <QrCodeIcon />
                  </Button>

                  {copiedNotification ? (
                    <InputGroup.Text className={styles.copiedNotification}>
                      Copied
                    </InputGroup.Text>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => {
                        copy(depositAddresses.deposit_addresses[0], {
                          format: 'text/plain',
                        });

                        setCopiedNotification(true);

                        setTimeout(() => {
                          setCopiedNotification(false);
                        }, 2000);
                      }}
                    >
                      Copy address
                    </Button>
                  )}
                </InputGroup>

                <QrModal
                  address={depositAddresses.deposit_addresses[0]}
                  show={qrModalShow}
                  onClose={() => setQrModalShow(false)}
                />

                <div className={styles.gatewayWarning}>
                  <p>
                    <strong>
                      Send only {depositAddresses.currency.id} to this gateway
                      address
                    </strong>
                    . Sending any other currency to this address may result in
                    the loss of your funds.
                  </p>

                  <p>
                    <strong>
                      The minimum amount is{' '}
                      {depositAddresses.currency.allowed_amount.min}{' '}
                      {depositAddresses.currency.id}
                    </strong>
                    . If you send less than{' '}
                    {depositAddresses.currency.allowed_amount.min}{' '}
                    {depositAddresses.currency.id} you will lose your funds.
                  </p>
                </div>

                {metamaskPlatformIds.includes(platform.id) && (
                  <form
                    className={styles.metamaskForm}
                    onSubmit={async event => {
                      event.preventDefault();

                      try {
                        const { transferTokensThroughMetamask } = await import(
                          /* webpackChunkName: "metamask-top-up" */
                          './metamask'
                        );

                        const approved = await transferTokensThroughMetamask({
                          address: depositAddresses.deposit_addresses[0],
                          amountTokens: topUpAmount,
                          currency: depositAddresses.currency,
                          platform,
                        });

                        if (approved) {
                          notifications.show(
                            `Transfer of ${topUpAmount} ${depositAddresses.currency.id} successfully sent`,
                          );
                        }
                      } catch (err) {
                        notifications.showError(
                          isErrorLike(err)
                            ? err.message
                            : 'Could not top up with metamask. Unexpected error occured',
                        );
                      }
                    }}
                  >
                    <label className={styles.formHead} htmlFor="topUpAmount">
                      Or Top-up by Metamask Wallet
                    </label>

                    <InputGroup>
                      {assetInfo && (
                        <InputGroup.Text className={styles.topUpInputLogo}>
                          <img src={assetInfo.url} alt="" />
                        </InputGroup.Text>
                      )}

                      <IMaskInput
                        autoComplete="off"
                        className={clsx(styles.formControl, 'form-control')}
                        id="topUpAmount"
                        inputMode="decimal"
                        mapToRadix={['.', ',']}
                        mask={Number}
                        name="bid"
                        radix="."
                        scale={depositAddresses.currency.decimals}
                        thousandsSeparator=","
                        value={topUpAmountMasked}
                        onAccept={(_, mask) => {
                          setTopUpAmountMasked(mask.value);
                          setTopUpAmount(mask.unmaskedValue);
                        }}
                      />

                      <InputGroup.Text>
                        {depositAddresses.currency.id}
                      </InputGroup.Text>

                      <Button
                        disabled={
                          !topUpAmount ||
                          new BigNumber(topUpAmount).lt(
                            depositAddresses.currency.allowed_amount.min,
                          )
                        }
                        type="submit"
                        variant="primary"
                      >
                        Top-up by Metamask
                      </Button>
                    </InputGroup>
                  </form>
                )}

                <p className={styles.gatewayFooter}>
                  Approximately a minute after sending, the status of your
                  transaction will be visible in the history below.
                </p>
              </>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
}
