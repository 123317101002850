import { Money } from '@waves/data-entities';
import clsx from 'clsx';
import * as copyToClipboard from 'copy-to-clipboard';
import { LogoutIcon } from 'icons/logout';
import { WalletIcon } from 'icons/wallet';
import { WavesIcon } from 'icons/waves';
import { createAssetFromDetails } from 'modules/assets/utils';
import { useNotifications } from 'modules/notifications/context';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Form, Link, useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'shared/components/Button';
import { Ellipsis } from 'shared/components/Ellipsis';
import { useAppSelector } from 'store/hooks';

import * as styles from './userInfo.module.css';

interface Props {
  isSideBar?: boolean;
  onConnectWalletClick?: () => void;
}

export function UserInfo({ isSideBar, onConnectWalletClick }: Props) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const notifications = useNotifications();
  const wallet = useAppSelector(state => state.wallet.active);

  const wavesBalance = useAppSelector(state =>
    state.balances.WAVES
      ? Money.fromCoins(
          state.balances.WAVES.balance,
          createAssetFromDetails(state.assets.WAVES),
        )
      : null,
  );

  const logoSize = isSideBar ? 24 : 16;

  const next =
    location.pathname === '/connect-wallet'
      ? searchParams.get('next') || ''
      : location.pathname + location.search + location.hash;

  return (
    <div className={clsx(styles.box, isSideBar ? styles.sideBarBox : null)}>
      {wallet ? (
        <>
          <div>
            <OverlayTrigger
              defaultShow={false}
              placement="bottom"
              overlay={<Tooltip>Copy address</Tooltip>}
            >
              <div
                className={clsx(styles.row, styles.addressRow)}
                onClick={() => {
                  copyToClipboard(wallet.address, {
                    format: 'text/plain',
                  });
                  notifications.show(`${wallet.address} copied`);
                }}
              >
                <WalletIcon width={logoSize} height={isSideBar ? 20 : 16} />
                <span
                  className={clsx(
                    styles.addressText,
                    isSideBar ? styles.sideBarText : null,
                  )}
                >
                  <Ellipsis text={wallet.address} />
                </span>
              </div>
            </OverlayTrigger>

            <div className={styles.row}>
              <WavesIcon width={logoSize} height={logoSize} />
              <span className={styles.balanceText}>
                {wavesBalance?.toFormat(wavesBalance.asset.precision) ?? 0}
              </span>
            </div>
          </div>

          <Form action="/logout" method="post">
            <input type="hidden" name="next" value={location.pathname} />

            <Button
              align="center"
              before={<LogoutIcon />}
              className={clsx(
                styles.logoutBtn,
                isSideBar ? styles.sideBarText : null,
              )}
              type="submit"
              variant="primary-outline"
            >
              Log out
            </Button>
          </Form>
        </>
      ) : (
        <Link
          to={`/connect-wallet${next && `?${new URLSearchParams({ next })}`}`}
          onClick={onConnectWalletClick}
        >
          <Button
            as="span"
            before={
              <WalletIcon width={logoSize} height={isSideBar ? 20 : 16} />
            }
            className={clsx(
              styles.loginBtn,
              isSideBar ? styles.sideBarText : null,
            )}
            variant="primary-outline"
          >
            Connect your wallet
          </Button>
        </Link>
      )}
    </div>
  );
}
