import { Card, Spinner } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';

import * as styles from './mobileStatus.module.css';
import { AuctionTimer } from './timer';

export function MobileAuctionStatus() {
  const { data } = useAppSelector(state => state.auction);

  if (!data) {
    return <Spinner />;
  }

  return (
    <Card className={styles.container}>
      <Card.Body className={styles.body}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <span className={styles.prop}>Auction №:</span>{' '}
            <span className={styles.value}>{data.auctionId}</span>
          </div>
          <div className={styles.row}>
            <span className={styles.prop}>Phase:</span>
            <span
              className={styles.value}
              style={{
                color: {
                  BID: '#009E3F',
                  REVEAL: '#FE8A04',
                }[data.phase],
              }}
            >
              {
                {
                  BID: 'Bid',
                  REVEAL: 'Reveal',
                }[data.phase]
              }
            </span>
          </div>
          <div className={styles.row}>
            <span className={styles.prop}>Next phase in</span>
            <span className={styles.value}>
              <AuctionTimer
                until={
                  data.phase === 'REVEAL' ? data.auctionEnd : data.revealStart
                }
              />
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
