import { isAbortError } from 'modules/errors/utils';

export function startPolling(
  ms: number,
  fn: (abortSignal: AbortSignal) => Promise<void>,
) {
  const abortController = new AbortController();
  let timeout: ReturnType<typeof setTimeout>;

  async function update() {
    try {
      await fn(abortController.signal);
    } catch (err) {
      if (isAbortError(err)) {
        return;
      }

      throw err;
    } finally {
      timeout = setTimeout(update, ms);
    }
  }

  update();

  return () => {
    clearTimeout(timeout);
    abortController.abort();
  };
}
