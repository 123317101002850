import { GOOGLE_ANALYTICS_ID } from 'shared/config';

export function gtag(event: 'js', date: Date): void;
export function gtag(event: 'config', id: string): void;
export function gtag() {
  if (!__NODE__) {
    const w = window as typeof window & { dataLayer?: unknown[] };
    w.dataLayer ||= [];
    // eslint-disable-next-line prefer-rest-params
    w.dataLayer.push(arguments);
  }
}

if (GOOGLE_ANALYTICS_ID) {
  gtag('js', new Date());
  gtag('config', GOOGLE_ANALYTICS_ID);
}
