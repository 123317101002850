import { type MetaDescriptor } from '_core/meta';
import { PageContent, PageTitle } from 'layout/layout';

import * as styles from './about.module.css';

export const aboutMeta: MetaDescriptor = {
  title: 'About',
};

export function AboutPage() {
  return (
    <>
      <PageTitle>About</PageTitle>

      <PageContent>
        <div className={styles.root}>
          <p>
            Waves Domains is the Waves ecosystem&apos;s naming service that
            gives users an opportunity to truly own their names in the form of
            NFT. Initially, NFT names are sold through a sealed-bid Vickrey
            auction to ensure fair market prices. An NFT name can be stored,
            transferred, or traded at any time. Besides being a proof of
            ownership, NFT names bear some additional functionality, such as the
            ability to be linked to a wallet address or a website. In the future
            updates, an NFT name will also grant governance rights to the early
            adopters.
          </p>

          <p>
            <a
              href="https://medium.com/waves-domains/waves-domains-decentralized-naming-service-40ca71827477"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more at medium.com
            </a>
          </p>
        </div>
      </PageContent>
    </>
  );
}
