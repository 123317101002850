import { useEffect, useState } from 'react';

interface Props {
  className?: string;
  until: number;
}

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const pad = (l: number, n: number) => n.toString().padStart(l, '0');

export function AuctionTimer({ className, until }: Props) {
  const [now, setNow] = useState(() => Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const timeDiff = until - now;
  const hours = Math.floor(timeDiff / HOUR);
  const minutes = Math.floor((timeDiff % HOUR) / MINUTE);
  const seconds = Math.floor((timeDiff % MINUTE) / SECOND);

  return (
    <span className={className} suppressHydrationWarning>
      {timeDiff < 60000
        ? 'Less than 1 min'
        : `${pad(2, hours)}:${pad(2, minutes)}:${pad(2, seconds)}`}
    </span>
  );
}
