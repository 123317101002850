import clsx from 'clsx';
import { forwardRef } from 'react';
import { Button as BsButton, type ButtonProps } from 'react-bootstrap';

import * as styles from './Button.module.css';

interface Props extends ButtonProps {
  after?: React.ReactElement;
  align?: 'start' | 'center' | 'end';
  before?: React.ReactElement;
  innerClassName?: string;
}

export const Button = forwardRef(function Button(
  { before, after, children, align = 'start', innerClassName, ...rest }: Props,
  ref: React.ForwardedRef<SVGSymbolElement | null>,
) {
  return (
    <BsButton {...rest} ref={ref}>
      <div
        className={clsx(
          styles.inner,
          {
            [styles.end]: align === 'end',
            [styles.start]: align === 'start',
            [styles.center]: align === 'center',
          },
          innerClassName,
        )}
      >
        {before && <div className={styles.before}>{before}</div>}
        <div>{children}</div>
        {after && <div>{after}</div>}
      </div>
    </BsButton>
  );
});
