export function WalletIcon({
  height = 16,
  width = 16,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M1.5 4c0-1.013.82-1.833 1.833-1.833h9.334c1.012 0 1.833.82 1.833 1.833v8c0 1.012-.82 1.833-1.833 1.833H3.333A1.833 1.833 0 0 1 1.5 12V4Zm12 5.5v-3H9.333a1.5 1.5 0 0 0 0 3H13.5Zm0-4H9.333a2.5 2.5 0 0 0 0 5H13.5V12c0 .46-.373.833-.833.833H3.333A.833.833 0 0 1 2.5 12V4c0-.46.373-.833.833-.833h9.334c.46 0 .833.373.833.833v1.5Zm-4.167 2a.5.5 0 0 1 .5.5v.007a.5.5 0 1 1-1 0V8a.5.5 0 0 1 .5-.5Z"
      />
    </svg>
  );
}
