export function WavesIcon({
  height = 16,
  width = 16,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#f4f4f9" />
      <path fill="#1f5af6" d="M1.5 8 8 1.5 14.5 8 8 14.5z" />
    </svg>
  );
}
