import { enums, type Infer, number, object, string } from 'superstruct';

export const WalletProvider = enums([
  'cloud',
  'keeper',
  'keeperMobile',
  'ledger',
  'metamask',
  'web',
]);

export type WalletProvider = Infer<typeof WalletProvider>;

export const Wallet = object({
  address: string(),
  provider: WalletProvider,
  publicKey: string(),
});

export type Wallet = Infer<typeof Wallet>;

export const WxAccessToken = object({
  access_token: string(),
  expires_in: number(),
  refresh_token: string(),
  scope: string(),
  token_type: string(),
});

export type WxAccessToken = Infer<typeof WxAccessToken>;
