import { BrowserCookies } from 'cookies/cookies';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createAppStore } from 'store/createAppStore';
import invariant from 'tiny-invariant';

import { Entry } from './entry';
import { createRoutes } from './routes';

invariant(window.__INITIAL_REDUX_STATE__);
const initialReduxState = window.__INITIAL_REDUX_STATE__;

const store = createAppStore(
  {
    cookies: new BrowserCookies(),
  },
  initialReduxState,
);

const { __ENTRY_CONTEXT__ } = window;
invariant(__ENTRY_CONTEXT__);

const { css, js, nonce, routerState } = __ENTRY_CONTEXT__;

__webpack_nonce__ = nonce;

const routes = createRoutes(origin, store);

const router = createBrowserRouter(routes, {
  hydrationData: routerState,
});

export function BrowserRoot() {
  return (
    <Entry
      css={css}
      js={js}
      initialReduxState={initialReduxState}
      nonce={nonce}
      origin={origin}
      router={router}
      routes={routes}
      store={store}
      userLanguages={navigator.languages.slice()}
    >
      <RouterProvider router={router} />
    </Entry>
  );
}

if (import.meta.webpackHot) {
  interface HotData {
    shouldRevalidate?: boolean;
  }

  const hotData: HotData | undefined = import.meta.webpackHot.data;

  if (hotData?.shouldRevalidate) {
    router.revalidate();
  }

  import.meta.webpackHot.accept('./routes');

  import.meta.webpackHot.dispose((data: HotData) => {
    data.shouldRevalidate = true;
    __ENTRY_CONTEXT__.routerState = router.state;
  });
}
