import { startPolling } from '_core/polling';
import { updateAuctionData } from 'auction/redux';
import { clearWavesBalance, updateWavesBalance } from 'balances/redux';
import { syncBidsFromLocalStorage } from 'bids/redux';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface Props {
  children: React.ReactNode;
}

export function AppContainer({ children }: Props) {
  const dispatch = useAppDispatch();
  const wallet = useAppSelector(state => state.wallet.active);

  useEffect(() => {
    dispatch(syncBidsFromLocalStorage());
  }, [dispatch]);

  useEffect(
    () =>
      startPolling(5000, abortSignal =>
        dispatch(updateAuctionData({ abortSignal })),
      ),
    [dispatch],
  );

  useEffect(() => {
    if (!wallet) {
      return;
    }

    const stopPolling = startPolling(5000, abortSignal =>
      dispatch(updateWavesBalance({ abortSignal })),
    );

    return () => {
      dispatch(clearWavesBalance());
      stopPolling();
    };
  }, [dispatch, wallet]);

  return <>{children}</>;
}
