import { Layout } from 'layout/layout';
import {
  type IndexRouteObject,
  type NonIndexRouteObject,
  Outlet,
} from 'react-router-dom';
import { createLogoutAction } from 'routes/logout';
import { type AppStore } from 'store/types';

import { type MetaDescriptor, type MetaFunctionArgs } from './_core/meta';
import { Document } from './document';
import { errorMeta, ErrorPage } from './routes/_error';
import { aboutMeta, AboutPage } from './routes/about';
import {
  auctionMeta,
  AuctionPage,
  createAuctionLoader,
} from './routes/auction';
import {
  connectWalletMeta,
  ConnectWalletPage,
} from './routes/connectWallet/connectWallet';
import { createNamesLoader, namesMeta, NamesPage } from './routes/names';
import { createTopUpLoader, topUpMeta, TopUpPage } from './routes/topUp/topUp';
import { whoisLoader, whoisMeta, WhoisPage } from './routes/whois';

interface AppRouteParams {
  meta?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((args: MetaFunctionArgs<any>) => MetaDescriptor | void) | MetaDescriptor;
}

type AppIndexRouteInput = IndexRouteObject & AppRouteParams;

type AppNonIndexRouteInput = Omit<NonIndexRouteObject, 'children' | 'id'> & {
  children?: AppRouteInput[];
} & AppRouteParams;

type AppRouteInput = AppIndexRouteInput | AppNonIndexRouteInput;

export type AppRouteObject = (AppIndexRouteInput | AppNonIndexRouteInput) & {
  id: string;
};

function convertRoutes(
  routes: AppRouteInput[],
  parentPath: number[] = [],
): AppRouteObject[] {
  return routes.map((route, index) => {
    const treePath = [...parentPath, index];
    const id = treePath.join('-');

    if (route.index) {
      return { ...route, id };
    }

    const pathOrLayoutRoute: AppNonIndexRouteInput & { id: string } = {
      ...route,
      id,
      children: route.children
        ? convertRoutes(route.children, treePath)
        : undefined,
    };

    return pathOrLayoutRoute;
  });
}

export function createRoutes(origin: string, store: AppStore) {
  return convertRoutes([
    {
      path: '/',
      meta: {
        'application-name': 'Waves Domains',
        description:
          'Decentralized naming service working on the Waves blockchain',
        'og:image': new URL('/og-default.jpg', origin).toString(),
        'og:image:width': '1200',
        'og:image:height': '630',
        'twitter:card': 'summary_large_image',
      },
      element: (
        <Document>
          <Outlet />
        </Document>
      ),
      errorElement: (
        <Document>
          <ErrorPage />
        </Document>
      ),
      children: [
        {
          meta: errorMeta,
          element: (
            <Layout>
              <Outlet />
            </Layout>
          ),
          errorElement: (
            <Layout>
              <ErrorPage />
            </Layout>
          ),
          children: [
            {
              index: true,
              loader: whoisLoader,
              meta: whoisMeta,
              element: <WhoisPage />,
            },
            {
              path: 'about',
              meta: aboutMeta,
              element: <AboutPage />,
            },
            {
              path: 'auction',
              loader: createAuctionLoader(store),
              meta: auctionMeta,
              element: <AuctionPage />,
            },
            {
              path: 'connect-wallet',
              meta: connectWalletMeta,
              element: <ConnectWalletPage />,
            },
            {
              path: 'logout',
              action: createLogoutAction(store),
            },
            {
              path: 'names',
              loader: createNamesLoader(store),
              meta: namesMeta,
              element: <NamesPage />,
            },
            {
              path: 'top-up',
              loader: createTopUpLoader(store),
              meta: topUpMeta,
              element: <TopUpPage />,
            },
            {
              path: '*',
              loader: () => {
                throw new Response(
                  "The page you're looking for doesn't exist.",
                  {
                    status: 404,
                    statusText: 'Page Not Found',
                  },
                );
              },
              element: null,
            },
          ],
        },
      ],
    },
  ]);
}
