import { type HydrationState, type Router } from '@remix-run/router';
import { NotificationsProvider } from 'modules/notifications/context';
import { UniqIdProvider } from 'modules/uniqId/context';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { type AppRouteObject } from 'routes';
import { AppContainer } from 'shared/components/AppContainer';
import { type AppState, type AppStore } from 'store/types';
import invariant from 'tiny-invariant';

interface EntryContext {
  css: string[];
  js: string[];
  initialReduxState: AppState;
  nonce: string;
  origin: string;
  routerState: HydrationState;
  routes: AppRouteObject[];
  userLanguages: string[];
}

const EntryContext = createContext<EntryContext | null>(null);

interface ProviderProps {
  children: React.ReactNode;
  css: string[];
  js: string[];
  initialReduxState: AppState;
  nonce: string;
  origin: string;
  router: Router;
  routes: AppRouteObject[];
  store: AppStore;
  userLanguages: string[];
}

export function Entry({
  children,
  css,
  js,
  initialReduxState,
  nonce,
  origin,
  router,
  routes,
  store,
  userLanguages,
}: ProviderProps) {
  const [{ actionData, errors, loaderData }, setRouterState] = useState(
    router.state,
  );

  useEffect(() => router.subscribe(setRouterState), [router]);

  const value = useMemo(
    (): EntryContext => ({
      css,
      js,
      initialReduxState,
      nonce,
      origin,
      routerState: { actionData, errors, loaderData },
      routes,
      userLanguages,
    }),
    [
      actionData,
      css,
      errors,
      initialReduxState,
      js,
      loaderData,
      nonce,
      origin,
      routes,
      userLanguages,
    ],
  );

  return (
    <EntryContext.Provider value={value}>
      <UniqIdProvider>
        <NotificationsProvider>
          <Provider store={store}>
            <AppContainer>{children}</AppContainer>
          </Provider>
        </NotificationsProvider>
      </UniqIdProvider>
    </EntryContext.Provider>
  );
}

export function useEntryContext() {
  const value = useContext(EntryContext);
  invariant(value, 'You must render EntryProvider higher in  the tree');
  return value;
}
