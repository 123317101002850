interface MetamaskChain {
  blockExplorerUrls?: string[];
  chainId: number;
  chainName: string;
  rpcUrls: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
}

export const metamaskChainsByPlatform: Record<
  string,
  MetamaskChain | undefined
> = {
  BSC: {
    blockExplorerUrls: ['https://bscscan.com'],
    chainId: 56,
    chainName: 'Binance Smart Chain Mainnet',
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  ETH: {
    blockExplorerUrls: ['https://etherscan.io'],
    chainId: 1,
    chainName: 'Ethereum Mainnet',
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  POLYGON: {
    blockExplorerUrls: ['https://polygonscan.com'],
    chainId: 137,
    chainName: 'Polygon Mainnet',
    rpcUrls: ['https://polygon-rpc.com/'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
};

export const metamaskPlatformIds = Object.keys(metamaskChainsByPlatform);
