import clsx from 'clsx';
import { Card, Figure } from 'react-bootstrap';

import { NftCanvas } from './canvas';
import * as styles from './preview.module.css';

interface Props {
  className?: string;
  name: string;
}

export function NftPreview({ className, name }: Props) {
  return (
    <Card className={clsx(className, styles.container)}>
      <Card.Body className={styles.inner}>
        <Figure className={styles.figure}>
          <NftCanvas name={name} />
          <Figure.Caption className={clsx(styles.caption, 'mt-2')}>
            <img
              src={new URL('./preview.svg', import.meta.url).pathname}
              alt=""
              width={179}
              height={46}
            />
          </Figure.Caption>
        </Figure>
      </Card.Body>
    </Card>
  );
}
