import { MediumSocialIcon } from 'icons/social/medium';
import { TelegramSocialIcon } from 'icons/social/telegram';
import { TwitterSocialIcon } from 'icons/social/twitter';
import { Stack } from 'react-bootstrap';

import * as styles from './SocialLinks.module.css';

interface LinkProps {
  href: string;
  children?: React.ReactNode;
  className?: string;
  target?: string;
}

function Link({ className, children, href, target }: LinkProps) {
  return (
    <a className={className} href={href} target={target}>
      {children}
    </a>
  );
}

interface SocialLinksProps {
  className?: string;
}

export function SocialLinks({ className }: SocialLinksProps) {
  return (
    <Stack className={className}>
      <div className={styles.section}>
        <div className={styles.title}>NEWS</div>
        <Stack direction="horizontal" className={styles.links} gap={3}>
          <Link target="_blank" href="https://twitter.com/WavesDomains">
            <TwitterSocialIcon />
          </Link>
          <Link target="_blank" href="https://medium.com/waves-domains">
            <MediumSocialIcon />
          </Link>
          <Link target="_blank" href="https://t.me/WavesDomainsNews">
            <TelegramSocialIcon />
          </Link>
        </Stack>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>COMMUNITY</div>
        <Stack direction="horizontal" className={styles.links} gap={3}>
          <Link target="_blank" href="https://t.me/WavesDomains">
            <TelegramSocialIcon />
          </Link>
        </Stack>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>SUPPORT</div>
        <Stack className={styles.links} gap={3}>
          <Link
            target="_blank"
            className={styles.textLink}
            href="https://docs.waves.domains"
          >
            Docs
          </Link>
        </Stack>
      </div>
    </Stack>
  );
}
