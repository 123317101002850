import clsx from 'clsx';
import { type ReactNode } from 'react';

import * as styles from './Cell.module.css';

interface CellProps {
  align?: 'start' | 'end' | 'center';
  children?: ReactNode;
  className?: string;
  left?: ReactNode;
  right?: ReactNode;
}

export function Cell({ align, children, className, left, right }: CellProps) {
  return (
    <div
      className={clsx(styles.container, className, {
        [styles.start]: align === 'start',
        [styles.end]: align === 'end',
        [styles.center]: align === 'center',
      })}
    >
      {left && <div className={styles.left}>{left}</div>}
      <div className={styles.center}>{children}</div>
      {right && <div>{right}</div>}
    </div>
  );
}
