import { ClockIcon } from 'icons/clock';
import { LoupeIcon } from 'icons/loupe';
import { QuestionIcon } from 'icons/question';
import { SwapIcon } from 'icons/swap';
import { UserIcon } from 'icons/user';
import { Stack } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'shared/components/Button';

import { AuctionStatus } from '../../../auction/status';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import * as styles from './Sidebar.module.css';

interface SidebarItemProps {
  pathname: string;
  icon: React.ReactElement;
  text: string;
  onClick?: () => void;
}

function SidebarItem({ pathname, icon, text, onClick }: SidebarItemProps) {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <div className={styles.sidebarItem}>
      <Link to={pathname} onClick={onClick}>
        <Button
          as="span"
          before={icon}
          active={
            location.pathname === pathname ||
            searchParams.get('next') === pathname
          }
          variant="outline-primary"
        >
          {text}
        </Button>
      </Link>
    </div>
  );
}

interface SidebarProps {
  className?: string;
  showAuction?: boolean;
  showSocial?: boolean;
  onItemClick?: () => void;
}

export function Sidebar({
  className,
  showAuction = true,
  showSocial = true,
  onItemClick,
}: SidebarProps) {
  return (
    <div className={className}>
      <Stack className="pb-3" gap={3}>
        <SidebarItem
          pathname="/"
          icon={<LoupeIcon />}
          text="Whois"
          onClick={onItemClick}
        />

        <SidebarItem
          pathname="/names"
          icon={<UserIcon />}
          text="My names"
          onClick={onItemClick}
        />

        <SidebarItem
          pathname="/auction"
          icon={<ClockIcon />}
          text="Auction"
          onClick={onItemClick}
        />

        <SidebarItem
          pathname="/top-up"
          icon={<SwapIcon />}
          text="Top-up"
          onClick={onItemClick}
        />

        <SidebarItem
          pathname="/about"
          icon={<QuestionIcon />}
          text="About"
          onClick={onItemClick}
        />

        {showAuction && (
          <div className="mt-3">
            <AuctionStatus />
          </div>
        )}

        {showSocial && (
          <div className="mt-4">
            <SocialLinks />
          </div>
        )}
      </Stack>
    </div>
  );
}
