export function CrossIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      <path d="M2.97977 2.97994C3.17503 2.78468 3.49161 2.78468 3.68688 2.97994L7.99999 7.29306L12.3131 2.97994C12.5084 2.78468 12.8249 2.78468 13.0202 2.97994C13.2155 3.1752 13.2155 3.49179 13.0202 3.68705L8.7071 8.00016L13.0202 12.3133C13.2155 12.5085 13.2155 12.8251 13.0202 13.0204C12.825 13.2157 12.5084 13.2157 12.3131 13.0204L7.99999 8.70727L3.68687 13.0204C3.4916 13.2157 3.17502 13.2157 2.97976 13.0204C2.7845 12.8251 2.7845 12.5085 2.97976 12.3133L7.29288 8.00016L2.97977 3.68705C2.78451 3.49179 2.78451 3.1752 2.97977 2.97994Z" />
    </svg>
  );
}
