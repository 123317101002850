import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type AppThunkAction } from 'store/types';

import { PrivateBidDataRecord } from './types';

const LOCAL_STORAGE_KEY = 'bidsPrivateData';

interface State {
  privateBidsData: PrivateBidDataRecord | null;
}

const initialState: State = {
  privateBidsData: null,
};

const { actions, reducer } = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    updateBidsPrivateData(state, action: PayloadAction<PrivateBidDataRecord>) {
      state.privateBidsData = action.payload;
    },
  },
});

export default reducer;

export function syncBidsFromLocalStorage(): AppThunkAction<void> {
  return dispatch => {
    function updateValue(newValue: string | null) {
      dispatch(
        actions.updateBidsPrivateData(
          newValue ? PrivateBidDataRecord.mask(JSON.parse(newValue)) : {},
        ),
      );
    }

    updateValue(localStorage.getItem(LOCAL_STORAGE_KEY));

    addEventListener(
      'storage',
      event => {
        if (
          event.storageArea !== localStorage ||
          event.key !== LOCAL_STORAGE_KEY
        ) {
          return;
        }

        updateValue(event.newValue);
      },
      false,
    );
  };
}

export function addPrivateBidsData(
  newData: PrivateBidDataRecord,
): AppThunkAction<void> {
  return (dispatch, getState) => {
    const state = getState();
    const existingData = state.bids.privateBidsData;
    const updatedData = { ...existingData, ...newData };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedData));
    dispatch(
      actions.updateBidsPrivateData(PrivateBidDataRecord.mask(updatedData)),
    );
  };
}
