import {
  base58Decode,
  base58Encode,
  base64Decode,
  blake2b,
  concat,
  keccak,
  stringToBytes,
} from '@waves/ts-lib-crypto';
import Long from 'long';
import { isNotNull } from 'shared/helpers/isNotNull';
import invariant from 'tiny-invariant';

import {
  type Bid,
  type BidDataEntry,
  type NotRevealedBid,
  PrivateBidDataRecord,
  type RevealDataEntry,
  type RevealedBid,
  type TopDataEntry,
} from './types';

export function privateBidsDataToBlob(record: PrivateBidDataRecord) {
  return new Blob([JSON.stringify(record)], {
    type: 'application/json; charset=utf-8',
  });
}

export async function privateBidsDataFromBlob(blob: Blob) {
  const bidsString = await blob.text();
  return PrivateBidDataRecord.mask(JSON.parse(bidsString));
}

export function makeRevealKey({
  address,
  auctionId,
  hash,
}: {
  address: string;
  auctionId: number;
  hash: string;
}) {
  return `Reveal_${auctionId}_${address}_${hash}`;
}

export function makeTopKey({ name }: { name: string }) {
  return `Top_${name}`;
}

function makeRevealedBid(
  revealDataEntry: RevealDataEntry,
  { topDataEntries }: { topDataEntries: Partial<Record<string, TopDataEntry>> },
): RevealedBid {
  const topDataEntry = topDataEntries[makeTopKey(revealDataEntry)];
  invariant(topDataEntry);

  return {
    ...revealDataEntry,
    id: makeBidId(revealDataEntry),
    isRevealed: true,
    isTop: topDataEntry.revealKey === makeRevealKey(revealDataEntry),
    price: topDataEntry.price,
  };
}

function makeNotRevealedBid(bidDataEntry: BidDataEntry): NotRevealedBid {
  return {
    ...bidDataEntry,
    id: makeBidId(bidDataEntry),
    isRevealed: false,
  };
}

export function getAllRevealedBidsForName(
  name: string,
  {
    revealDataEntries,
    topDataEntries,
  }: {
    revealDataEntries: Partial<Record<string, RevealDataEntry>>;
    topDataEntries: Partial<Record<string, TopDataEntry>>;
  },
) {
  return Object.values(revealDataEntries)
    .filter(isNotNull)
    .filter(entry => entry.name === name)
    .map(revealDataEntry =>
      makeRevealedBid(revealDataEntry, { topDataEntries }),
    );
}

export function getBidsFromDataEntries({
  bidDataEntries,
  revealDataEntries,
  topDataEntries,
}: {
  bidDataEntries: Partial<Record<string, BidDataEntry>>;
  revealDataEntries: Partial<Record<string, RevealDataEntry>>;
  topDataEntries: Partial<Record<string, TopDataEntry>>;
}) {
  return Object.values(bidDataEntries)
    .filter(isNotNull)
    .map((bidDataEntry): Bid => {
      const revealDataEntry = revealDataEntries[makeRevealKey(bidDataEntry)];

      return revealDataEntry
        ? makeRevealedBid(revealDataEntry, { topDataEntries })
        : makeNotRevealedBid(bidDataEntry);
    });
}

export function makeBidHash({
  name,
  amount,
  secret,
  address,
}: {
  name: string;
  amount: string;
  secret: string;
  address: string;
}) {
  const amountArrayNumbers = Long.fromString(amount);
  const amountArrayBytes = amountArrayNumbers.toBytes();
  const amountBytes = Uint8Array.from(amountArrayBytes);
  const nameBytes = stringToBytes(name);
  const addrBytes = base58Decode(address);
  const secretBytes = base64Decode(secret);
  const hashb2b = blake2b(
    keccak(concat(nameBytes, amountBytes, addrBytes, secretBytes)),
  );

  return base58Encode(hashb2b);
}

export function makeBidId({
  address,
  auctionId,
  hash,
}: {
  auctionId: number;
  hash: string;
  address: string;
}) {
  return `${auctionId}_${address}_${hash}`;
}

export function makeSingleBidBackupFileName({
  auctionId,
  hash,
}: {
  auctionId: number;
  hash: string;
}) {
  return `bid-${auctionId}-${hash}.json`;
}

export function makeMultipleBidsBackupFileName() {
  const date = new Date();
  const year = date.getFullYear() % 100;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const pad = (l: number, n: number) => n.toString().padStart(l, '0');

  return `bids-${year}${pad(2, month)}${pad(2, day)}${pad(2, hours)}${pad(
    2,
    minutes,
  )}${pad(2, seconds)}.json`;
}
