import { createSlice } from '@reduxjs/toolkit';

import { type AssetDetailsItem } from './types';

type BalancesState = Partial<Record<string, AssetDetailsItem>> & {
  WAVES: AssetDetailsItem;
};

export const initialState: BalancesState = {
  WAVES: {
    assetId: 'WAVES',
    decimals: 8,
    description: '',
    issueHeight: 0,
    issueTimestamp: 1460408400000,
    issuer: '',
    issuerPublicKey: '',
    minSponsoredAssetFee: null,
    name: 'Waves',
    originTransactionId: '',
    quantity: '10000000000000000',
    reissuable: false,
    scripted: false,
  },
};

const { reducer } = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
});

export default reducer;
