import { useEffect, useRef, useState } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import invariant from 'tiny-invariant';
import { clearPendingWallet, switchToPendingWallet } from 'wallet/redux';

import * as styles from './switchModal.module.css';

export function WalletSwitchModal() {
  const dispatch = useAppDispatch();
  const activeWallet = useAppSelector(state => state.wallet.active);
  const pendingWallet = useAppSelector(state => state.wallet.pending);
  const [show, setShow] = useState(pendingWallet != null);

  useEffect(() => {
    if (pendingWallet) {
      setShow(true);
    }
  }, [pendingWallet]);

  const chosenActionRef = useRef<(() => void) | null>(null);

  if (!activeWallet || !pendingWallet) {
    return null;
  }

  return (
    <Modal
      backdrop="static"
      centered
      show={show}
      onExited={() => {
        const chosenAction = chosenActionRef.current;
        chosenActionRef.current = null;
        invariant(chosenAction);
        chosenAction();
      }}
    >
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.text}>
          <p>
            The account you&apos;re signing the transaction with is different
            from the one you connected to Waves Domains.
          </p>

          <p>
            If you want to proceed using the current account, choose the switch
            option.
          </p>

          <p>
            If it&apos;s a mistake, please log into the correct account in your
            wallet.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Stack gap={2}>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              chosenActionRef.current = () => {
                dispatch(switchToPendingWallet());
              };

              setShow(false);
            }}
          >
            Switch account
          </Button>

          <Button
            size="lg"
            variant="link"
            onClick={() => {
              chosenActionRef.current = () => {
                dispatch(clearPendingWallet());
              };

              setShow(false);
            }}
          >
            Choose correct account
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
