// extracted by mini-css-extract-plugin
export var arrow = "wgtmF9XHaGmkFfU5DEuO";
export var description = "pGZeDXaIFtNdAwzY0zva";
export var header = "xbyGKisnpULgH7ZnxThn";
export var headerImage = "bw9O3zO6tlCDPUglO_N9";
export var headerText = "vYH7Uh6fsLGnHraQeuwR";
export var item = "bFLRVeMjBjgXbXYmGUK4";
export var logo = "CRHItEIpBtIhblGtJhOe";
export var name = "hrF1SVsKEqnAMznpNdUM";
export var text = "qrQMBqwHEsAqKFd8Alvt";
export var wallets = "F0LZlwvy00TP_WO8MGFw";