import clsx from 'clsx';
import { useEntryContext } from 'entry';
import {
  type MovementsHistoryItem,
  type PlatformsItem,
} from 'modules/gateways/api';
import { Spinner } from 'react-bootstrap';

import * as styles from './history.module.css';

interface Props {
  items: MovementsHistoryItem[] | null;
  platforms: PlatformsItem[] | null;
}

export function TopUpHistory({ items, platforms }: Props) {
  const { userLanguages } = useEntryContext();

  const dateFormat = new Intl.DateTimeFormat(userLanguages, {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  const platformsRecord =
    platforms &&
    Object.fromEntries(platforms.map(platform => [platform.id, platform]));

  return (
    <div className={styles.box}>
      {items == null ? (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      ) : items.length === 0 ? (
        <p className={styles.noItemsMessage}>
          History is empty, no transactions yet
        </p>
      ) : (
        <>
          <div className={styles.itemList}>
            {items.map(item => (
              <div key={item.id} className={styles.item}>
                <div
                  className={clsx(
                    styles.itemBadge,
                    {
                      deposit: styles.itemBadge_deposit,
                      withdrawal: styles.itemBadge_withdrawal,
                    }[item.direction],
                  )}
                >
                  {
                    {
                      deposit: `Deposit from ${
                        platformsRecord
                          ? platformsRecord[item.source_platform_id].name
                          : item.source_platform_id
                      }`,

                      withdrawal: `Withdrawal to ${
                        platformsRecord
                          ? platformsRecord[item.destination_platform_id].name
                          : item.destination_platform_id
                      }`,
                    }[item.direction]
                  }
                </div>

                <div className={styles.itemAmount}>
                  {item.direction === 'withdrawal' && '–'}
                  {item.amount} {item.currency_id}
                </div>

                <div className={styles.itemDate}>
                  {dateFormat.format(new Date(item.created_at))}
                </div>

                <div
                  className={clsx(
                    styles.itemStatus,
                    {
                      in_progress: styles.itemStatus_inProgress,
                      pending_approval: styles.itemStatus_inProgress,
                      confirmed: styles.itemStatus_confirmed,
                      rejected: undefined,
                    }[item.status],
                  )}
                >
                  {{
                    in_progress: 'In Progress',
                    pending_approval: 'Pending Approval',
                    confirmed: 'Succeeded',
                    rejected: 'Failed',
                  }[item.status] ?? item.status}
                </div>
              </div>
            ))}
          </div>

          <p className={styles.footNote}>
            You can see earlier transactions and more details at{' '}
            <a
              href="https://waves.exchange/wallet/deposit-withdraw-transactions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Waves.Exchange
            </a>
          </p>
        </>
      )}
    </div>
  );
}
