import { Money } from '@waves/data-entities';
import { makeSingleBidBackupFileName } from 'bids/utils';
import clsx from 'clsx';
import { createAssetFromDetails } from 'modules/assets/utils';
import { useAppSelector } from 'store/hooks';

import * as styles from './row.module.css';
import { type NotRevealedBid } from './types';

interface Props {
  currentAuctionId: number;
  bidEntry: NotRevealedBid;
}

export function BidRow({ currentAuctionId, bidEntry }: Props) {
  const assets = useAppSelector(state => state.assets);
  const isExpired = bidEntry.auctionId < currentAuctionId;

  return (
    <div className={styles.root}>
      <div
        className={clsx(
          styles.badge,
          isExpired ? styles.badge_theme_muted : styles.badge_theme_warning,
        )}
      >
        {isExpired ? 'Expired' : 'Restore needed'}
      </div>

      <div className={styles.text}>
        <p className={styles.description}>
          {isExpired
            ? 'This is an expired Bid without data. To get info on the lot and bid amount, please Restore data from a file.'
            : 'You need to Restore the file with the name below to access this bid!'}
        </p>

        <p className={styles.filename}>
          {makeSingleBidBackupFileName(bidEntry)}
        </p>
      </div>

      <dl className={styles.props}>
        <dt className={styles.propLabel}>Your deposit</dt>
        <dd className={styles.propValue}>
          {Money.fromCoins(
            bidEntry.deposit,
            createAssetFromDetails(assets.WAVES),
          ).toFormat(assets.WAVES.decimals)}
        </dd>
      </dl>
    </div>
  );
}
