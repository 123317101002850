import invariant from 'tiny-invariant';

export const GOOGLE_ANALYTICS_ID = __NODE__
  ? process.env.GOOGLE_ANALYTICS_ID
  : window.__GOOGLE_ANALYTICS_ID__;

export const INTERCOM_ID = __NODE__
  ? process.env.INTERCOM_ID
  : window.__INTERCOM_ID__;

const ALLOWED_NETWORKS = ['mainnet', 'testnet'] as const;
type Network = (typeof ALLOWED_NETWORKS)[number];

const __NETWORK__ = __NODE__
  ? (process.env.NETWORK as Network)
  : window.__NETWORK__;

invariant(__NETWORK__);
invariant(ALLOWED_NETWORKS.includes(__NETWORK__));

export const NETWORK = __NETWORK__;

interface NetworkConfig {
  auctionAddress: string;
  nodeBaseUrl: string;
  wavesRegistrarAddress: string;
}

export const NETWORK_CONFIG: NetworkConfig =
  NETWORK === 'testnet'
    ? {
        auctionAddress: '3N7LWLXDtaXAyrzFjxhPPLBfV5DepLY4JQT',
        nodeBaseUrl: 'https://nodes-testnet.wavesnodes.com',
        wavesRegistrarAddress: '3NA73oUXjqp7SpudXWV1yMFuKm9awPbqsVz',
      }
    : {
        auctionAddress: '3PEAqJwXYEiGJ4vdpKfRF48TFQ1gWfByR9S',
        nodeBaseUrl: 'https://nodes.wavesnodes.com',
        wavesRegistrarAddress: '3P6utP25F4wMUGG4hGdDtMXMKu2tTeNkgRs',
      };
