// extracted by mini-css-extract-plugin
export var box = "srf7jsiD6M3zkGudyYzz";
export var footNote = "LQN7KYrDvfOrFNcZt6YY";
export var item = "_XDXEAeKgJBdj5VSl9Iq";
export var itemAmount = "cZBQNTTeSASxn9QzY8RE";
export var itemBadge = "Fz9uI46N0nHqeXb6rtij";
export var itemBadge_deposit = "SkY5bBkcXrG7B0MUUS3n";
export var itemBadge_withdrawal = "KHrSwpJi1qrWVCinW3JD";
export var itemDate = "kRX3MaoH5z_adDDRxFIE";
export var itemList = "y17Po92xtyq6zA7A7OP1";
export var itemStatus = "Oan4S78YjagucQI6bD6L";
export var itemStatus_confirmed = "b24Mp2hTZZhohni9RagN";
export var itemStatus_inProgress = "v3T9soROfUqtvU1svM7s";
export var noItemsMessage = "o0cOuRtO2Tc793NB5b5t";
export var spinnerWrapper = "fYhQaMKVQuxH7p7LGja5";