import { NETWORK_CONFIG } from 'shared/config';
import { number, string, type Struct, type, union } from 'superstruct';

class EvaluateScriptError extends Error {
  error;

  constructor(error: number, message: string) {
    super(message);
    this.error = error;
  }
}

export async function evaluateScript<T, S>(
  expr: string,
  dApp: string,
  valueStruct: Struct<T, S>,
  { abortSignal }: { abortSignal?: AbortSignal } = {},
) {
  const requestUrl = new URL(
    `/utils/script/evaluate/${dApp}`,
    NETWORK_CONFIG.nodeBaseUrl,
  );

  const response = await fetch(requestUrl, {
    signal: abortSignal,
    method: 'POST',
    headers: {
      accept: 'application/json; large-significand-format=string',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ expr }),
  });

  if (!response.ok) {
    throw response;
  }

  const struct = union([
    type({
      result: type({
        value: valueStruct,
      }),
    }),
    type({
      error: number(),
      message: string(),
    }),
  ]);

  const json = struct.create(await response.json());

  if ('error' in json) {
    throw new EvaluateScriptError(json.error, json.message);
  }

  return json;
}
