import { HideOn } from 'modules/responsive/hideOn';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import BsNavbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import { MobileNavbar } from './mobileNavbar';
import * as styles from './navbar.module.css';
import { NavbarSearch } from './search';
import { UserInfo } from './userInfo';

export function Navbar() {
  return (
    <>
      <HideOn query="(min-width: 1225px)">
        <MobileNavbar />
      </HideOn>

      <HideOn query="(max-width: 1224px)">
        <BsNavbar bg="light" expand className={styles.navbar}>
          <Container className={styles.inner} fluid>
            <Link className={styles.logo} to="/">
              <img
                src={new URL('./logo.svg', import.meta.url).pathname}
                alt="Waves Domains Logo"
                width={142}
                height={14}
              />
            </Link>

            <Nav>
              <NavbarSearch />
            </Nav>

            <Nav className={styles.userInfo}>
              <UserInfo />
            </Nav>

            <BsNavbar.Toggle aria-controls="offcanvasNavbar-expand" />
          </Container>
        </BsNavbar>
      </HideOn>
    </>
  );
}
