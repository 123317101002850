export function CopyIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M9.25 5C9.25 3.48122 10.4812 2.25 12 2.25H19C20.5188 2.25 21.75 3.48122 21.75 5V12C21.75 13.5188 20.5188 14.75 19 14.75H17.5C17.0858 14.75 16.75 14.4142 16.75 14C16.75 13.5858 17.0858 13.25 17.5 13.25H19C19.6904 13.25 20.25 12.6904 20.25 12V5C20.25 4.30964 19.6904 3.75 19 3.75H12C11.3096 3.75 10.75 4.30964 10.75 5V6.5C10.75 6.91421 10.4142 7.25 10 7.25C9.58579 7.25 9.25 6.91421 9.25 6.5V5ZM2.25 12C2.25 10.4812 3.48122 9.25 5 9.25H12C13.5188 9.25 14.75 10.4812 14.75 12V19C14.75 20.5188 13.5188 21.75 12 21.75H5C3.48122 21.75 2.25 20.5188 2.25 19V12ZM5 10.75C4.30964 10.75 3.75 11.3096 3.75 12V19C3.75 19.6904 4.30964 20.25 5 20.25H12C12.6904 20.25 13.25 19.6904 13.25 19V12C13.25 11.3096 12.6904 10.75 12 10.75H5Z" />
    </svg>
  );
}
