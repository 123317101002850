import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { evaluateScript } from 'nodeApi/nodeApi';
import { NETWORK_CONFIG } from 'shared/config';
import { type AppThunkAction } from 'store/types';
import { array, enums, string, type } from 'superstruct';

export interface AuctionData {
  auctionEnd: number;
  auctionId: number;
  bidStart: number;
  phase: 'BID' | 'REVEAL';
  revealStart: number;
  minNameLength: number;
  minBidAmounts: string[];
}

interface State {
  data: AuctionData | null;
}

const initialState: State = {
  data: null,
};

const { actions, reducer } = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<AuctionData>) => {
      state.data = payload;
    },
  },
});

export default reducer;

export function updateAuctionData({
  abortSignal,
}: {
  abortSignal: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async dispatch => {
    const { result } = await evaluateScript(
      'getAuction()',
      NETWORK_CONFIG.auctionAddress,
      type({
        _1: type({ value: string() }),
        _2: type({ value: enums(['BID', 'REVEAL']) }),
        _3: type({ value: string() }),
        _4: type({ value: string() }),
        _5: type({ value: string() }),
        _6: type({ value: string() }),
        _7: type({ value: array(type({ value: string() })) }),
      }),
      { abortSignal },
    );

    dispatch(
      actions.update({
        auctionEnd: Number(result.value._5.value),
        auctionId: Number(result.value._1.value),
        bidStart: Number(result.value._3.value),
        minBidAmounts: result.value._7.value.map(({ value }) => value),
        minNameLength: Number(result.value._6.value),
        phase: result.value._2.value,
        revealStart: Number(result.value._4.value),
      }),
    );
  };
}
