import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  BroadcastOptions,
  InvokeArgs,
  Provider,
  Signer,
} from '@waves/signer';
import { NETWORK, NETWORK_CONFIG } from 'shared/config';
import { type AppThunkAction } from 'store/types';
import invariant from 'tiny-invariant';

import { Wallet, type WalletProvider, WxAccessToken } from './types';
import { wavesAddress2eth } from './utils';

const WALLET_COOKIE_NAME = 'wallet';
const WALLET_COOKIE_MAX_AGE = 365 * 24 * 60 * 60;

const WX_ACCESS_TOKEN_COOKIE_NAME = 'wxAccessToken';
const WX_ACCESS_TOKEN_COOKIE_MAX_AGE = 7 * 24 * 60 * 60;

interface State {
  active: Wallet | null;
  pending: Wallet | null;
  wxAccessToken: WxAccessToken | null;
}

const initialState: State = {
  active: null,
  pending: null,
  wxAccessToken: null,
};

const { actions, reducer } = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setActiveWallet(state, action: PayloadAction<Wallet | null>) {
      state.active = action.payload;
    },
    setPendingWallet(state, action: PayloadAction<Wallet>) {
      state.pending = action.payload;
    },
    clearPendingWallet(state) {
      state.pending = null;
    },
    setWxAccessToken(state, action: PayloadAction<WxAccessToken | null>) {
      state.wxAccessToken = action.payload;
    },
  },
});

export default reducer;

export const { clearPendingWallet } = actions;

async function createProvider(provider: WalletProvider): Promise<Provider> {
  switch (provider) {
    case 'cloud': {
      const { ProviderCloud } = await import(
        /* webpackChunkName: "provider-cloud" */
        '@waves.exchange/provider-cloud'
      );

      return NETWORK === 'testnet'
        ? new ProviderCloud('https://testnet.waves.exchange/signer-cloud/')
        : new ProviderCloud();
    }
    case 'keeper': {
      const { ProviderKeeper } = await import(
        /* webpackChunkName: "provider-keeper" */
        '@waves/provider-keeper'
      );

      return new ProviderKeeper();
    }
    case 'keeperMobile': {
      const { ProviderKeeperMobile } = await import(
        /* webpackChunkName: "provider-keeper-mobile" */
        '@keeper-wallet/provider-keeper-mobile'
      );

      return new ProviderKeeperMobile({
        name: 'Waves Domains',
        icon: 'https://avatars.githubusercontent.com/u/114963468?s=200&v=4',
      });
    }
    case 'ledger': {
      const { ProviderLedger } = await import(
        /* webpackChunkName: "provider-ledger" */
        '@waves/provider-ledger'
      );

      return new ProviderLedger();
    }
    case 'metamask': {
      const { ProviderMetamask } = await import(
        /* webpackChunkName: "provider-metamask" */
        '@waves/provider-metamask'
      );

      return new ProviderMetamask();
    }
    case 'web': {
      const { ProviderWeb } = await import(
        /* webpackChunkName: "provider-web" */
        '@waves.exchange/provider-web'
      );

      return NETWORK === 'testnet'
        ? new ProviderWeb('https://testnet.waves.exchange/signer/')
        : new ProviderWeb();
    }
  }
}

const { disconnectSigner, getSigner } = (() => {
  let signer: Signer | undefined;

  return {
    async disconnectSigner() {
      if (signer) {
        await signer.logout();
        signer = undefined;
      }
    },
    getSigner(): AppThunkAction<Promise<Signer>> {
      return async (dispatch, getState) => {
        const state = getState();
        const wallet = state.wallet.active;

        if (!signer) {
          const { Signer } = await import(
            /* webpackChunkName: "signer" */
            '@waves/signer'
          );

          signer = new Signer({
            NODE_URL: NETWORK_CONFIG.nodeBaseUrl,
          });
        }

        if (wallet) {
          if (!signer.currentProvider) {
            await signer.setProvider(await createProvider(wallet.provider));
          }

          const userData = await signer.login();

          if (
            wallet.address !== userData.address ||
            wallet.publicKey !== userData.publicKey
          ) {
            dispatch(actions.setPendingWallet({ ...wallet, ...userData }));
            throw new Error('Wallet in signer has changed');
          }
        }

        return signer;
      };
    },
  };
})();

export function connectWallet(
  provider: WalletProvider,
): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { cookies }) => {
    const [signer, providerInstance] = await Promise.all([
      dispatch(getSigner()),
      createProvider(provider),
    ]);

    await signer.setProvider(providerInstance);

    const wallet = Wallet.mask(
      Object.assign({}, await signer.login(), { provider }),
    );

    cookies.set(WALLET_COOKIE_NAME, JSON.stringify(wallet), {
      maxAge: WALLET_COOKIE_MAX_AGE,
    });

    dispatch(actions.setActiveWallet(wallet));
  };
}

export function disconnectWallet(): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { cookies }) => {
    cookies.remove(WALLET_COOKIE_NAME);
    dispatch(actions.setActiveWallet(null));

    cookies.remove(WX_ACCESS_TOKEN_COOKIE_NAME);
    dispatch(actions.setWxAccessToken(null));

    await disconnectSigner();
  };
}

export function restoreWallet(): AppThunkAction<void> {
  return (dispatch, _getState, { cookies }) => {
    let wallet: Wallet | null = null;

    const cookieValue: unknown = JSON.parse(
      cookies.get(WALLET_COOKIE_NAME) ?? 'null',
    );

    if (Wallet.is(cookieValue)) {
      wallet = cookieValue;
    }

    dispatch(actions.setActiveWallet(wallet));
  };
}

export function switchToPendingWallet(): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { cookies }) => {
    const state = getState();
    const pendingWallet = state.wallet.pending;
    invariant(pendingWallet);

    cookies.remove(WX_ACCESS_TOKEN_COOKIE_NAME);
    dispatch(actions.setWxAccessToken(null));

    dispatch(actions.clearPendingWallet());

    cookies.set(WALLET_COOKIE_NAME, JSON.stringify(pendingWallet), {
      maxAge: WALLET_COOKIE_MAX_AGE,
    });

    dispatch(actions.setActiveWallet(pendingWallet));
  };
}

export function obtainWxAccessToken(): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { cookies }) => {
    const signer = await dispatch(getSigner());

    const state = getState();
    const wallet = state.wallet.active;
    invariant(wallet);

    const username =
      wallet.provider === 'metamask'
        ? wavesAddress2eth(wallet.address)
        : wallet.publicKey;

    const client_id = 'waves.exchange';
    const chainId = 'W';
    const expiresAt =
      Math.round(Date.now() / 1000) + WX_ACCESS_TOKEN_COOKIE_MAX_AGE;
    const message = `${chainId}:${client_id}:${expiresAt}`;
    const signature = await signer.signMessage(message);

    const password = `${expiresAt}:${signature}`;

    const response = await fetch('https://api.wx.network/v1/oauth2/token', {
      method: 'POST',
      body: new URLSearchParams({
        client_id,
        scope: 'general',
        grant_type: 'password',
        username,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error(
        `Could not obtain wx access token: ${await response.text()}`,
      );
    }

    const wxAccessToken: WxAccessToken = await response.json();

    cookies.set(WX_ACCESS_TOKEN_COOKIE_NAME, JSON.stringify(wxAccessToken), {
      maxAge: WX_ACCESS_TOKEN_COOKIE_MAX_AGE,
    });

    dispatch(actions.setWxAccessToken(wxAccessToken));
  };
}

let refreshWxAccessTokenPromise: Promise<WxAccessToken> | undefined;
export function refreshWxAccessToken(): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { cookies }) => {
    const state = getState();
    const { wxAccessToken } = state.wallet;
    invariant(wxAccessToken);
    const { refresh_token } = wxAccessToken;

    if (!refreshWxAccessTokenPromise) {
      refreshWxAccessTokenPromise = fetch(
        'https://api.wx.network/v1/oauth2/token',
        {
          method: 'POST',
          body: new URLSearchParams({
            client_id: 'waves.exchange',
            scope: 'general',
            grant_type: 'refresh_token',
            refresh_token,
          }),
        },
      )
        .then(response =>
          response.ok
            ? response.json().then((newWxAccessToken: WxAccessToken) => {
                cookies.set(
                  WX_ACCESS_TOKEN_COOKIE_NAME,
                  JSON.stringify(newWxAccessToken),
                  {
                    maxAge: WX_ACCESS_TOKEN_COOKIE_MAX_AGE,
                  },
                );

                return newWxAccessToken;
              })
            : (cookies.remove(WX_ACCESS_TOKEN_COOKIE_NAME),
              response
                .text()
                .then(text =>
                  Promise.reject(
                    new Error(`Could not refresh wx access token: ${text}`),
                  ),
                )),
        )
        .finally(() => {
          refreshWxAccessTokenPromise = undefined;
        });
    }

    try {
      const newWxAccessToken = await refreshWxAccessTokenPromise;

      dispatch(actions.setWxAccessToken(newWxAccessToken));
    } catch {
      dispatch(actions.setWxAccessToken(null));
    }
  };
}

export function restoreWxAccessToken(): AppThunkAction<void> {
  return (dispatch, _getState, { cookies }) => {
    let wxAccessToken: WxAccessToken | null = null;

    const cookieValue: unknown = JSON.parse(
      cookies.get(WX_ACCESS_TOKEN_COOKIE_NAME) ?? 'null',
    );

    if (WxAccessToken.is(cookieValue)) {
      wxAccessToken = cookieValue;
    }

    dispatch(actions.setWxAccessToken(wxAccessToken));
  };
}

type BroadcastResult<T extends 'invoke'> = Awaited<
  ReturnType<ReturnType<Signer[T]>['broadcast']>
>[0];

export function invoke(
  invokeArgs: InvokeArgs,
  broadcastOptions?: BroadcastOptions,
): AppThunkAction<Promise<BroadcastResult<'invoke'>>> {
  return async dispatch => {
    const signer = await dispatch(getSigner());

    return signer
      .invoke({
        payment: [],
        ...invokeArgs,
      })
      .broadcast(broadcastOptions)
      .then(result => (Array.isArray(result) ? result[0] : result));
  };
}
