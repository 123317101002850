// extracted by mini-css-extract-plugin
export var badge = "lPf3x9RWN3PeoibAN9Iv";
export var badge_theme_muted = "LuPIijuebwfbe0JtKISx";
export var badge_theme_warning = "EZr9PNjSPkKwd9c7OJSi";
export var description = "CI4Heyjyb409YJ5kjkAV";
export var filename = "mJyoUtJK1HgQEYlSoCEB";
export var propLabel = "J4OW3KeYg1vyPgv2_Ijj";
export var propValue = "HBW0NnlA43aG2mh24_Tg";
export var props = "FQMI29IxITjOWeoWUi4x";
export var root = "Wluo0kHv2pPqT5JdN0VD";
export var text = "hK9CMJE760aa6ExnwPcV";