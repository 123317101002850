// extracted by mini-css-extract-plugin
export var cards = "lAF7N2pS7AoR7OytgCqO";
export var expiredRows = "IeSKkSoL3GVweYjDqLj7";
export var header = "h1PZB928oJ5Yw_3ousHg";
export var heading = "rsmWZUgHbtnwRSMozxd0";
export var more = "mNRakZNmsmStW6c8s3aQ";
export var moreCheckbox = "EMPydXj50R4JuEAsEecl";
export var placeholder = "vyXBs6idIrTSZGXuRcPB";
export var restoreBanner = "IYJqQPc2XIp77OgpnAw3";
export var restoreBannerHeader = "BwRWkwg_LsWjvKw6Cq2K";
export var restoreBannerHeading = "itsRlyRLMNHLCSiSjIdR";
export var restoreBannerItems = "F80zxrHJMWlBKJP8QFGd";
export var restoreBannerMain = "uQ_dMBLb2JMfBZeIpEBR";
export var restoreBannerSupportBtn = "EvkXHM97tv6pGZeR2YZg";
export var restoreBannerText = "NwNtqHk5Vy9C0Z74it0x";
export var restoreFileInput = "nI8fVO7r_cTXp16vWDiQ";
export var spinnerWrapper = "lOyXNyTSGY6A_svAlk2J";
export var toolbar = "HFFKkjRv6VJc8TTHT9gP";