import { NFT_CANVAS_HEIGHT, NFT_CANVAS_WIDTH } from './renderNftPicture';

interface Props {
  assetId: string;
}

export function NftImg({ assetId }: Props) {
  return (
    <img
      alt=""
      src={`/api/v1/nft-img/${assetId}`}
      width={NFT_CANVAS_WIDTH}
      height={NFT_CANVAS_HEIGHT}
    />
  );
}
