import { parse, serialize } from 'cookie';
import type * as express from 'express';

interface SetOptions {
  maxAge: number;
}

export abstract class Cookies {
  abstract get(name: string): string | undefined;
  abstract set(name: string, value: string, options: SetOptions): void;

  protected serialize(name: string, value: string, options: SetOptions) {
    return serialize(name, value, {
      ...options,
      path: '/',
      sameSite: 'strict',
      secure: true,
    });
  }

  remove(name: string) {
    this.set(name, '', { maxAge: 0 });
  }
}

export class BrowserCookies extends Cookies {
  get(name: string) {
    return parse(document.cookie)[name];
  }

  set(name: string, value: string, options: SetOptions) {
    document.cookie = this.serialize(name, value, options);
  }
}

export class ExpressCookies extends Cookies {
  #req: express.Request;
  #res: express.Response;

  constructor(req: express.Request, res: express.Response) {
    super();
    this.#req = req;
    this.#res = res;
  }

  get(name: string) {
    return parse(this.#req.headers.cookie ?? '')[name];
  }

  set(name: string, value: string, options: SetOptions) {
    this.#res.cookie(name, value, options);
  }
}
