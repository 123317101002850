import clsx from 'clsx';
import { CrossIcon } from 'icons/cross';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as styles from './notifications.module.css';
import { type NotificationItem, NotificationType } from './types';

interface Props {
  notifications: NotificationItem[];
  onClose: (notificationId: number) => void;
}

const colors = {
  [NotificationType.SUCCESS]: styles.success,
  [NotificationType.ERROR]: styles.error,
};

export function Notifications({ notifications, onClose }: Props) {
  return (
    <TransitionGroup className={styles.root} component="div" enter={false}>
      {notifications.map(notification => (
        <CSSTransition
          key={notification.id}
          classNames={{
            exit: styles.item_exit,
            exitActive: styles.item_exitActive,
          }}
          timeout={{
            exit: 200,
          }}
        >
          <div className={clsx(styles.item, colors[notification.type])}>
            <div className={styles.itemText}>{notification.message}</div>

            <button
              className={styles.closeButton}
              type="button"
              onClick={() => {
                onClose(notification.id);
              }}
            >
              <CrossIcon className={styles.closeButtonIcon} />
            </button>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}
