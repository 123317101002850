// extracted by mini-css-extract-plugin
export var badge = "O2JsRG8IRbQAmVg29s8v";
export var badge_theme_fail = "y33ly9YmiYBdsU0ZxWtX";
export var badge_theme_muted = "cwd0_8vxEsy0Z2O1rJsk";
export var badge_theme_success = "ZK3OqoENHefTPGDMYAav";
export var badge_theme_warning = "tvO6egYiNjpVbH2eKIIh";
export var bidAction = "UOzCJWz0ehvRGozUTJan";
export var bidActionButton = "Vb3284jLIzcTNkcUw1aX";
export var bidCallToAction = "p6wr31CLdMFBaqhu9wUJ";
export var bidCallToActionDescription = "jKhjoZ0n4cbuLipEvUy9";
export var bidCallToActionTimer = "Yz9h5KUn1uzFYKd0cSCA";
export var bidProps = "rKk96ktqcJPsSIL_auRa";
export var bidPropsLabel = "_SMlEb3SoBUW64VtrNKz";
export var bidPropsValue = "oHbXTteymXAhEZ_czJE4";
export var bidStatsBids = "IssvgGEi3UdbznbsOaGT";
export var bidStatsBidsBody = "eBwlLIIQItY41szt24ug";
export var bidStatsBidsBodyRow = "OPHKWHebWbVWVF_Bp8sh";
export var bidStatsBidsBodyRow_currentLoser = "BM_Ka3ZJjn6LC1T6yVTg";
export var bidStatsBidsBodyRow_currentWinner = "UOncXe2xr8PnpfOtCxX3";
export var bidStatsBidsCell = "i3aMs7OiEtGAhV6Wb2wg";
export var bidStatsBidsCell_numeric = "AgFCAD3crqWY_0g6t53a";
export var bidStatsBidsCell_place = "OlzoDVkMtS5utHhon4aZ";
export var bidStatsBidsCell_right = "hPakeMS0dZnWTr3LFw3x";
export var bidStatsBidsCell_td = "lvnyJfpcVZQg3AXSzHcZ";
export var bidStatsBidsCell_th = "EnJV5DkrzC3IdJMdSbJQ";
export var bidStatsBidsHead = "pQ2dlp_ebP6l9S09zFtE";
export var bidStatsBidsHeadRow = "UBrKfTeWjDHOvnTGF_n9";
export var bidStatsBidsTable = "HgG6H1uRG47GnRmWJkwg";
export var bidStatsHideButton = "rLFpOecD_90G7ZZ4WVib";
export var bidStatsPagination = "MYEELjLuPnuHsorqpk_R";
export var bidStatsPaginationItem = "cUE4dwrQB288hlb7y8So";
export var bidStatsPaginationItem_active = "XrHXZZci4PzPpazt5E2j";
export var bidStatsSummary = "kclaoxyZFA5HKjJjI4ra";
export var bidStatsSummaryCell = "AlpqaI5zTssPM67LwlBA";
export var bidStatsSummaryCell_right = "lkfOk3IkW5Ph6qBUxBz3";
export var bidStatsSummaryCell_td = "NPFIS57o9DzIw2Yf5dMD";
export var bidStatsSummaryCell_th = "IkytoUzAPWSdK4rFvn5D";
export var bidStatsSummaryTable = "Hhj_s2Wnzu1K4EcuV5TG";
export var bidStatsTitle = "HzBtUzII5gqHitpCP5sC";
export var footer = "cQpEtzYxLzKS95J1SNZO";
export var main = "x6XR9nFsQJ_jfsm9ogf8";
export var name = "G0fv1PjWCcQos6tfX5cO";
export var nftPreview = "syKHvQjhs3MQRDMuer2T";
export var nftPreviewWrapper = "uviGUPrE3pctzjrGWfkg";
export var nftPreview_blurred = "oVJSxW188khS28vYrdvv";
export var placeBadge = "l6IRhNIckEIKFbcSyYan";
export var placeBadge_loser = "b0VRtzbMA1Q3EEEc4iz2";
export var placeBadge_winner = "ttplSxRNEoV5sQBHTKrU";
export var root = "wnDmXABqb6qCxqIum6tw";
export var rootBack = "Bvgm208Xqe4A49zvbjZx";
export var rootFront = "I2HSeBCe8A0tSqY0ihwE";
export var rootInner = "St8kNyLcme7wcgkn5PgQ";
export var root_isFlipped = "Xt1OTj_VrKiW6vPeYT9R";
export var statsButton = "TSpkE_IRh7MyKiwdyQp7";
export var statsButtonText = "KStahB119K1kVDA2ojnF";
export var statsButtonTextWrapper = "rgRE7pFNBDcKqhhoepWo";