import { type DataTransactionEntryString } from '@waves/ts-types';
import { evaluateScript } from 'nodeApi/nodeApi';
import { NETWORK_CONFIG } from 'shared/config';
import { boolean, type } from 'superstruct';

export async function getAuctionPricesForNames(names: string[]) {
  if (names.length === 0) {
    return {};
  }

  const topKey = (name: string) => `Top_${name}`;

  const response = await fetch(
    new URL(
      `/addresses/data/${NETWORK_CONFIG.auctionAddress}`,
      NETWORK_CONFIG.nodeBaseUrl,
    ),
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        keys: names.map(name => topKey(name)),
      }),
    },
  );

  if (!response.ok) {
    throw new Error(
      `Could not fetch names details (${response.status} ${
        response.statusText
      }): ${await response.text()}`,
    );
  }

  const dataEntries: DataTransactionEntryString[] = await response.json();

  const entriesRecord = Object.fromEntries<string | undefined>(
    dataEntries.map(entry => [entry.key, entry.value]),
  );

  return Object.fromEntries(
    names.map((name): [string, string | null] => {
      const topEntryValue = entriesRecord[topKey(name)];

      if (!topEntryValue) {
        return [name, null];
      }

      const [, auctionPrice] = topEntryValue.split(',');

      return [name, auctionPrice];
    }),
  );
}

export async function isNameAvailableOnAuction(name: string) {
  const data = await evaluateScript(
    `isAvailable(${JSON.stringify(name)})`,
    NETWORK_CONFIG.auctionAddress,
    type({
      _2: type({ value: boolean() }),
    }),
  );

  return data.result.value._2.value;
}
