import { type MetaDescriptor } from '_core/meta';
import { Money } from '@waves/data-entities';
import { useEntryContext } from 'entry';
import { PageContent, PageTitle } from 'layout/layout';
import { createAssetFromDetails } from 'modules/assets/utils';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { type LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { getNamesOwnedBy } from 'registrar/api';
import { useAppSelector } from 'store/hooks';
import { type AppStore } from 'store/types';
import { requireWallet } from 'wallet/requireWallet';

import { NftImg } from '../nfts/img';
import * as styles from './names.module.css';

export function createNamesLoader(store: AppStore) {
  return async ({ request }: LoaderFunctionArgs) => {
    const wallet = requireWallet(store, request);
    const names = await getNamesOwnedBy(wallet.address);

    return {
      names,
    };
  };
}

type LoaderData = Awaited<ReturnType<ReturnType<typeof createNamesLoader>>>;

export const namesMeta: MetaDescriptor = {
  title: 'My names',
};

export function NamesPage() {
  const { names } = useLoaderData() as LoaderData;
  const { userLanguages } = useEntryContext();
  const wavesAsset = useAppSelector(state => state.assets.WAVES);

  const dateFormat = new Intl.DateTimeFormat(userLanguages, {
    dateStyle: 'short',
  });

  return (
    <>
      <PageTitle>My names</PageTitle>

      <PageContent>
        <Container>
          {names.length === 0 ? (
            <div className={styles.noNames}>
              You have no names yet. Go to <Link to="/auction">Auction</Link>{' '}
              page to get one!
            </div>
          ) : (
            <div className={styles.cardList}>
              {names.map(entry => (
                <article key={entry.name} className={styles.card}>
                  <h2 className={styles.cardName} title={entry.name}>
                    {entry.name}.waves
                  </h2>

                  <div className={styles.cardNft}>
                    <NftImg assetId={entry.token} />
                  </div>

                  <div className={styles.cardDetails}>
                    <div className={styles.cardDetailsRow}>
                      <div className={styles.cardDetailsLabel}>Created</div>

                      <div className={styles.cardDetailsValue}>
                        {dateFormat.format(entry.createdAt)}
                      </div>
                    </div>

                    <div className={styles.cardDetailsRow}>
                      <div className={styles.cardDetailsLabel}>
                        Expiration date
                      </div>

                      <div className={styles.cardDetailsValue}>
                        {dateFormat.format(entry.expiresAt)}
                      </div>
                    </div>

                    <div className={styles.cardDetailsRow}>
                      <div className={styles.cardDetailsLabel}>
                        Auction price
                      </div>

                      <div className={styles.cardDetailsValue}>
                        {entry.auctionPrice
                          ? `${Money.fromCoins(
                              entry.auctionPrice,
                              createAssetFromDetails(wavesAsset),
                            ).toFormat()} WAVES`
                          : 'Unknown'}
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          )}
        </Container>
      </PageContent>
    </>
  );
}
