import { loadImage } from 'canvas';
import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useRef } from 'react';

import {
  NFT_CANVAS_HEIGHT,
  NFT_CANVAS_WIDTH,
  renderNftPicture,
} from './renderNftPicture';

interface Props {
  className?: string;
  name: string;
}

export function NftCanvas({ className, name }: Props) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    Promise.all([
      loadImage(new URL('./bg.png', import.meta.url).pathname),
      new FontFaceObserver('Space Grotesk', { weight: 'bold' }).load(),
    ]).then(([bgImg]) => {
      renderNftPicture(canvas, bgImg, name);
    });
  }, [name]);

  return (
    <canvas
      ref={canvasRef}
      className={className}
      width={NFT_CANVAS_WIDTH}
      height={NFT_CANVAS_HEIGHT}
      style={{ maxWidth: '100%' }}
    />
  );
}
