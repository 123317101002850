export interface NotificationItem {
  id: number;
  message: React.ReactElement | string;
  type: NotificationType;
}

export enum NotificationType {
  ERROR = 'alert',
  SUCCESS = 'success',
}
