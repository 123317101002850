export function Ellipsis({
  text,
  chars = 6,
  className,
}: {
  text: string;
  chars?: number;
  className?: string;
}) {
  return (
    <div className={className} title={text ?? undefined}>
      {text && text.length > 2 * chars
        ? `${text.slice(0, chars)}...${text.slice(-chars)}`
        : text}
    </div>
  );
}
