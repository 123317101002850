// extracted by mini-css-extract-plugin
export var addressRow = "s8AS1lauRlz1sXNrcPTw";
export var addressText = "si3A6dbsLtSflDAGCX_R";
export var balance = "Q4m6u9Z48b_OkFZfgEGF";
export var balanceText = "dzQDT_725e7nGaJytMW3";
export var box = "OPT70kLWbD01WcEF7QkA";
export var container = "ZMdxE5u_SuK3q2Tng8lL";
export var loginBtn = "UyvLiJnXGaPe7pS2IscA";
export var logoutBtn = "P8yyEJS_ZX4yBSfYJ6tQ";
export var row = "AftI69iJcKBtsGYRy_en";
export var sideBarBox = "sURJkv_TBF6EKoM2mGSd";
export var sideBarText = "cPLkGe3RDN6hzjvkud0q";