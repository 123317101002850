import {
  type DataTransactionEntry,
  type DataTransactionEntryString,
} from '@waves/ts-types';
import { getAuctionPricesForNames } from 'auction/api';
import { NETWORK_CONFIG } from 'shared/config';

interface GetNftsItem {
  assetId: string;
  decimals: 0;
  description: string;
  issueHeight: number;
  issueTimestamp: number;
  issuer: string;
  issuerPublicKey: string;
  minSponsoredAssetFee: null;
  name: string;
  originTransactionId: string;
  quantity: 1;
  reissuable: false;
  scripted: boolean;
}

interface NameDetails {
  createdAt: number;
  expiresAt: number;
  token: string;
}

export interface NameEntry extends NameDetails {
  auctionPrice: string | null;
  name: string;
}

async function getNfts(address: string) {
  const response = await fetch(
    new URL(`/assets/nft/${address}/limit/1000`, NETWORK_CONFIG.nodeBaseUrl),
  );

  if (!response.ok) {
    throw new Error(
      `Could not fetch nfts (${response.status} ${
        response.statusText
      }): ${await response.text()}`,
    );
  }

  const nfts: GetNftsItem[] = await response.json();

  return nfts;
}

async function getRegistrarNamesFromNfts(nfts: GetNftsItem[]) {
  const registrarNfts = nfts.filter(
    nft => nft.issuer === NETWORK_CONFIG.wavesRegistrarAddress,
  );

  if (registrarNfts.length === 0) {
    return [];
  }

  const response = await fetch(
    new URL(
      `/addresses/data/${NETWORK_CONFIG.wavesRegistrarAddress}`,
      NETWORK_CONFIG.nodeBaseUrl,
    ),
    {
      method: 'POST',
      headers: {
        accept: 'application/json; large-significand-format=string',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        keys: registrarNfts.map(nft => `Token_${nft.assetId}_name`),
      }),
    },
  );

  if (!response.ok) {
    throw new Error(
      `Could not fetch registrar token entries (${response.status} ${
        response.statusText
      }): ${await response.text()}`,
    );
  }

  const dataEntries: DataTransactionEntryString[] = await response.json();

  return dataEntries.map(entry => entry.value);
}

async function getRegistrarNamesDetails(names: string[]) {
  if (names.length === 0) {
    return {};
  }

  const createdAtKey = (name: string) => `Name_${name}_createdAt`;
  const expiresAtKey = (name: string) => `Name_${name}_expiresAt`;
  const tokenKey = (name: string) => `Name_${name}_token`;

  const response = await fetch(
    new URL(
      `/addresses/data/${NETWORK_CONFIG.wavesRegistrarAddress}`,
      NETWORK_CONFIG.nodeBaseUrl,
    ),
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        keys: names.flatMap(name => [
          createdAtKey(name),
          expiresAtKey(name),
          tokenKey(name),
        ]),
      }),
    },
  );

  if (!response.ok) {
    throw new Error(
      `Could not fetch names details (${response.status} ${
        response.statusText
      }): ${await response.text()}`,
    );
  }

  const dataEntries: Array<DataTransactionEntry<number>> =
    await response.json();

  const entriesRecord = Object.fromEntries(
    dataEntries.map(entry => [entry.key, entry.value]),
  );

  return Object.fromEntries(
    names.map((name): [string, NameDetails] => [
      name,
      {
        createdAt: Number(entriesRecord[createdAtKey(name)]),
        expiresAt: Number(entriesRecord[expiresAtKey(name)]),
        token: String(entriesRecord[tokenKey(name)]),
      },
    ]),
  );
}

export async function getNamesOwnedBy(address: string) {
  const nfts = await getNfts(address);
  const registrarNames = await getRegistrarNamesFromNfts(nfts);

  const [namesDetails, auctionPrices] = await Promise.all([
    getRegistrarNamesDetails(registrarNames),
    getAuctionPricesForNames(registrarNames),
  ]);

  return registrarNames.map(
    (name): NameEntry => ({
      ...namesDetails[name],
      auctionPrice: auctionPrices[name],
      name,
    }),
  );
}
