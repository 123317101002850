// extracted by mini-css-extract-plugin
export var box = "t8z9yKPreGokkppgVVNd";
export var copiedNotification = "rpBrcSfANuNtjBF_9cZx";
export var formControl = "lh4QytnbTaVjfB9xm9HD";
export var formFootnote = "brZhhvagtNnvRrFDol0D";
export var formHead = "vFPNxmlWu_jETaKUubPQ";
export var formLabel = "UFlduDybQV3MEXZteksf";
export var gatewayFooter = "isnQ_RTVn8i45OdWHiF4";
export var gatewayInfo = "wy3WteqRDyU91k_BKS_s";
export var gatewayInfoSpinnerWrapper = "PIwwDhaFdJ9m0cqzYkHj";
export var gatewayWarning = "yxKM80S5JSuUs5LezXjL";
export var metamaskForm = "XspGMerlL6Tp4senh5rM";
export var resetButton = "ip_tJCsKYtk1DbCcuBKY";
export var topUpInputLogo = "bUWQE0Z65pwO8uZGvqci";