import { redirect } from 'react-router-dom';
import { type AppStore } from 'store/types';

export function requireWallet(store: AppStore, request: Request) {
  const state = store.getState();
  const wallet = state.wallet.active;

  if (!wallet) {
    const url = new URL(request.url);

    throw redirect(
      `/connect-wallet?${new URLSearchParams({
        next: url.pathname,
        showHeader: 'true',
      })}`,
    );
  }

  return wallet;
}
