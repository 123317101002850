import {
  type Infer,
  literal,
  number,
  object,
  optional,
  record,
  string,
  union,
} from 'superstruct';

export const PrivateBidData = object({
  address: string(),
  amount: string(),
  auctionId: number(),
  hash: string(),
  id: string(),
  name: string(),
  secret: string(),
  timestamp: number(),
});

export type PrivateBidData = Infer<typeof PrivateBidData>;

export const PrivateBidDataRecord = record(string(), optional(PrivateBidData));
export type PrivateBidDataRecord = Infer<typeof PrivateBidDataRecord>;

export interface BidDataEntry {
  address: string;
  auctionId: number;
  deposit: string;
  hash: string;
}

export interface RevealDataEntry extends BidDataEntry {
  amount: string;
  name: string;
}

export interface TopDataEntry {
  name: string;
  price: string;
  revealKey: string;
}

export type Bid = {
  address: string;
  auctionId: number;
  deposit: string;
  hash: string;
  id: string;
} & (
  | {
      isRevealed: false;
    }
  | {
      amount: string;
      isRevealed: true;
      isTop: boolean;
      name: string;
      price: string;
    }
);

export type RevealedBid = Extract<Bid, { isRevealed: true }>;
export type NotRevealedBid = Extract<Bid, { isRevealed: false }>;

const IntegeryDataEntry = object({
  key: string(),
  type: literal('integer'),
  value: string(),
});

type IntegeryDataEntry = Infer<typeof IntegeryDataEntry>;

const StringDataEntry = object({
  key: string(),
  type: literal('string'),
  value: string(),
});

type StringDataEntry = Infer<typeof StringDataEntry>;

export const DataEntry = union([IntegeryDataEntry, StringDataEntry]);
export type DataEntry = Infer<typeof DataEntry>;
