import clsx from 'clsx';

import * as styles from './IconButton.module.css';

interface Props {
  className?: string;
  icon: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export function IconButton({
  className,
  icon,
  type = 'button',
  onClick,
}: Props) {
  return (
    <button
      className={clsx(styles.button, className)}
      type={type}
      onClick={onClick}
    >
      {icon}
    </button>
  );
}
