import { addPrivateBidsData } from 'bids/redux';
import {
  getAllRevealedBidsForName,
  getBidsFromDataEntries,
  makeMultipleBidsBackupFileName,
  makeSingleBidBackupFileName,
  privateBidsDataFromBlob,
  privateBidsDataToBlob,
} from 'bids/utils';
import saveAs from 'file-saver';
import { BackupIcon } from 'icons/backup';
import { MoreIcon } from 'icons/more';
import { RestoreIcon } from 'icons/restore';
import { TelegramIcon } from 'icons/telegram';
import { isErrorLike } from 'modules/errors/utils';
import { useNotifications } from 'modules/notifications/context';
import { Spinner } from 'react-bootstrap';
import { Button } from 'shared/components/Button';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { StructError } from 'superstruct';
import invariant from 'tiny-invariant';

import { BidCard } from './card';
import * as styles from './list.module.css';
import { BidRow } from './row';
import {
  type Bid,
  type BidDataEntry,
  type NotRevealedBid,
  type RevealDataEntry,
  type TopDataEntry,
} from './types';

interface BidGroups {
  currentAuctionNoData: NotRevealedBid[];
  expiredAuctionNoData: NotRevealedBid[];
  otherBids: Bid[];
}

function rankBidEntryForSorting(currentAuctionId: number, bidEntry: Bid) {
  return bidEntry.auctionId === currentAuctionId
    ? !bidEntry.isRevealed
      ? 3
      : 2
    : bidEntry.isRevealed
    ? 1
    : 0;
}

interface Props {
  bidDataEntries: Partial<Record<string, BidDataEntry>>;
  revealDataEntries: Partial<Record<string, RevealDataEntry>>;
  topDataEntries: Partial<Record<string, TopDataEntry>>;
}

export function BidsList({
  bidDataEntries,
  revealDataEntries,
  topDataEntries,
}: Props) {
  const notifications = useNotifications();
  const dispatch = useAppDispatch();
  const auctionData = useAppSelector(state => state.auction.data);
  const privateBidsData = useAppSelector(state => state.bids.privateBidsData);

  const bidGroups =
    auctionData == null || privateBidsData == null
      ? null
      : getBidsFromDataEntries({
          bidDataEntries,
          revealDataEntries,
          topDataEntries,
        }).reduce<BidGroups>(
          (acc, bidEntry) => {
            const privateData = privateBidsData[bidEntry.id];

            if (!bidEntry.isRevealed && !privateData) {
              if (bidEntry.auctionId === auctionData.auctionId) {
                acc.currentAuctionNoData.push(bidEntry);
              } else {
                acc.expiredAuctionNoData.push(bidEntry);
              }
            } else {
              acc.otherBids.push(bidEntry);
            }

            return acc;
          },
          {
            currentAuctionNoData: [],
            expiredAuctionNoData: [],
            otherBids: [],
          },
        );

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.heading}>My bids</h2>

        <label
          aria-label="Toggle toolbar"
          className={styles.more}
          htmlFor="toolbarToggle"
        >
          <MoreIcon aria-hidden="true" />
        </label>

        <input
          className={styles.moreCheckbox}
          id="toolbarToggle"
          type="checkbox"
        />

        <div className={styles.toolbar}>
          <Button
            before={<BackupIcon />}
            disabled={!privateBidsData}
            variant="outline-primary"
            onClick={async () => {
              invariant(privateBidsData);

              saveAs(
                privateBidsDataToBlob(privateBidsData),
                makeMultipleBidsBackupFileName(),
              );
            }}
          >
            Backup
          </Button>

          <Button as="label" before={<RestoreIcon />} variant="outline-primary">
            Restore
            <input
              accept="application/json"
              className={styles.restoreFileInput}
              multiple
              type="file"
              value=""
              onChange={async event => {
                if (!event.target.files) return;

                const bidRecords = await Promise.all(
                  Array.from(event.target.files).map(file =>
                    privateBidsDataFromBlob(file).catch(err => {
                      notifications.showError(
                        err instanceof StructError
                          ? `"${file.name}" doesn't seem to be a bids backup file`
                          : isErrorLike(err)
                          ? err.message
                          : `Could not restore data from file ${file.name}. Unexpected error occurred`,
                      );

                      return {};
                    }),
                  ),
                );

                const mergedBidsRecord = bidRecords.reduce(
                  (acc, rec) => Object.assign(acc, rec),
                  {},
                );

                dispatch(addPrivateBidsData(mergedBidsRecord));

                const restoredBidsCount = Object.keys(mergedBidsRecord).length;

                notifications.show(
                  `Restored ${restoredBidsCount} bid${
                    restoredBidsCount === 1 ? '' : 's'
                  }`,
                );
              }}
            />
          </Button>
        </div>
      </div>

      {auctionData == null || bidGroups == null ? (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      ) : bidGroups.currentAuctionNoData.length === 0 &&
        bidGroups.expiredAuctionNoData.length === 0 &&
        bidGroups.otherBids.length === 0 ? (
        <div className={styles.placeholder}>
          You have no bids yet. Place a bid or Restore it from file.
        </div>
      ) : (
        <>
          {bidGroups.currentAuctionNoData.length !== 0 && (
            <div className={styles.restoreBanner}>
              <div className={styles.restoreBannerHeader}>
                <div className={styles.restoreBannerHeading}>
                  You have {bidGroups.currentAuctionNoData.length} Bid
                  {bidGroups.currentAuctionNoData.length === 1 ? '' : 's'}{' '}
                  without data on this device
                </div>

                <a
                  className={styles.restoreBannerSupportBtn}
                  href="https://t.me/WavesDomainsNews"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TelegramIcon />
                  Support
                </a>
              </div>

              <div className={styles.restoreBannerMain}>
                <div className={styles.restoreBannerText}>
                  If you did Backup, try to find a file similar to these{' '}
                  <mark>bids- … .json</mark>{' '}
                  <mark>
                    {makeSingleBidBackupFileName({
                      auctionId: auctionData.auctionId,
                      hash: ' … ',
                    })}
                  </mark>{' '}
                  and upload it by clicking the Restore button above. Or use a
                  device you did your Bids with.{' '}
                  <b>If you fail to Restore, you will lose your funds.</b>
                </div>

                <div className={styles.restoreBannerItems}>
                  {bidGroups.currentAuctionNoData.map(bidEntry => (
                    <BidRow
                      key={bidEntry.id}
                      currentAuctionId={auctionData.auctionId}
                      bidEntry={bidEntry}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className={styles.cards}>
            {bidGroups.otherBids
              .slice()
              .sort((a, b) => {
                const aRank = rankBidEntryForSorting(auctionData.auctionId, a);
                const bRank = rankBidEntryForSorting(auctionData.auctionId, b);

                if (aRank !== bRank) {
                  return bRank - aRank;
                }

                return b.auctionId - a.auctionId;
              })
              .map(bidEntry => {
                const privateData = privateBidsData?.[bidEntry.id];

                const name = bidEntry.isRevealed
                  ? bidEntry.name
                  : privateData?.name;

                return (
                  <BidCard
                    key={bidEntry.id}
                    allRevealdBids={
                      name
                        ? getAllRevealedBidsForName(name, {
                            revealDataEntries,
                            topDataEntries,
                          })
                        : undefined
                    }
                    auctionData={auctionData}
                    bid={bidEntry}
                    privateData={privateData}
                  />
                );
              })}
          </div>

          <div className={styles.expiredRows}>
            {bidGroups.expiredAuctionNoData.map(bidEntry => (
              <BidRow
                key={bidEntry.id}
                currentAuctionId={auctionData.auctionId}
                bidEntry={bidEntry}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
