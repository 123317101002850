import { configureStore } from '@reduxjs/toolkit';

import { reducer } from './reducer';
import { type AppState, type ThunkExtraArg } from './types';

export function createAppStore(
  thunkExtraArg: ThunkExtraArg,
  initialState?: AppState,
) {
  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: thunkExtraArg,
        },
      }),
    preloadedState: initialState,
  });

  import.meta.webpackHot?.accept('./reducer', () => {
    store.replaceReducer(reducer);
  });

  return store;
}
