import { Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import * as styles from './BidReadyModal.module.css';

interface Props {
  name: string;
  show: boolean;
  onConfirm: VoidFunction;
  onExited: () => void;
}

export function BidReadyModal({ name, show, onConfirm, onExited }: Props) {
  return (
    <Modal backdrop="static" centered show={show} onExited={onExited}>
      <Modal.Header>
        <Modal.Title>Your bid is ready!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <p>
            The bid you placed for{' '}
            <span className={styles.name}>{name}.waves</span> is already
            confirmed!
          </p>
          <p className="no-pads">
            Now backup your bid to keep it safe on any device.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Stack gap={2}>
          <Button size="lg" variant="primary" onClick={onConfirm}>
            Backup and continue
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
