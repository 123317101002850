import clsx from 'clsx';
import {
  Stack as StackComponent,
  type StackProps as StackComponentProps,
} from 'react-bootstrap';

import * as styles from './ResponsiveStack.module.css';

type ResponsiveStackProps = StackComponentProps;

export function ResponsiveStack({
  children,
  className,
  gap,
}: ResponsiveStackProps) {
  return (
    <StackComponent className={clsx(styles.stack, className)} gap={gap}>
      {children}
    </StackComponent>
  );
}
