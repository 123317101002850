import clsx from 'clsx';
import { useUniqId } from 'modules/uniqId/context';
import { cloneElement } from 'react';

import { useMatchesMedia } from './useMatchesMedia';

interface Props {
  children: React.ReactElement<{ className: string }>;
  query: string;
}

export function HideOn({ children, query }: Props) {
  const matches = useMatchesMedia(query);
  const uniqId = useUniqId();
  const hideOnClassName = `HideOn-${uniqId}`;

  return matches ? null : (
    <>
      {cloneElement(children, {
        className: clsx(children.props.className, {
          [hideOnClassName]: matches == null,
        }),
      })}

      {matches == null && (
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `@media ${query} { .${hideOnClassName} { display: none !important; } }`,
          }}
        />
      )}
    </>
  );
}
