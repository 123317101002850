import { ErrorResponse } from '@remix-run/router';
import { PageContent, PageTitle } from 'layout/layout';
import {
  isRouteErrorResponse,
  Link,
  useLocation,
  useRouteError,
} from 'react-router-dom';

import { type MetaFunctionArgs } from '../_core/meta';
import * as styles from './_error.module.css';

function convertErrorToErrorResponse(error: unknown) {
  return isRouteErrorResponse(error)
    ? error
    : new ErrorResponse(
        500,
        'Internal Server Error',
        'Something went terribly wrong on our side.',
      );
}

export function errorMeta({ error }: MetaFunctionArgs) {
  if (!error) return;

  const errorResponse = convertErrorToErrorResponse(error);

  return {
    title: `${errorResponse.status}: ${errorResponse.statusText}`,
  };
}

export function ErrorPage() {
  const error = useRouteError();
  const location = useLocation();
  const errorResponse = convertErrorToErrorResponse(error);

  return (
    <>
      <PageTitle>
        {errorResponse.status === 404
          ? `${errorResponse.status}: ${errorResponse.statusText}`
          : 'Error'}
      </PageTitle>

      <PageContent>
        <div className={styles.root}>
          <p>{errorResponse.data}</p>

          {errorResponse.status !== 404 && (
            <>
              <p>
                Please try to click the button below to reload it. If the
                problem persists, please contact support.
              </p>

              <p className={styles.buttons}>
                <Link
                  className="btn btn-primary"
                  to={location.pathname + location.search + location.hash}
                >
                  Try to reload
                </Link>

                <a
                  className="btn btn-outline-primary"
                  href="https://t.me/WavesDomainsNews"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Contact Support
                </a>
              </p>
            </>
          )}
        </div>
      </PageContent>
    </>
  );
}
