import { type MetaFunctionArgs } from '_core/meta';
import { type WhoIsResult, WhoIsStatus } from '@waves-domains/client';
import { isNameAvailableOnAuction } from 'auction/api';
import { STATIC_DOMAIN } from 'bids/constants';
import clsx from 'clsx';
import copyToClipboard from 'copy-to-clipboard';
import { useEntryContext } from 'entry';
import { CheckIcon } from 'icons/check';
import { CopyIcon } from 'icons/copy';
import { LoupeIcon } from 'icons/loupe';
import { HideOn } from 'modules/responsive/hideOn';
import { useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Container,
  Overlay,
  Row,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import {
  Form,
  Link,
  type LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { ResponsiveStack } from 'shared/components/ResponsiveStack/ResponsiveStack';
import { wavesDomainsClient } from 'shared/wavesDomainsClient';

import { PageContent, PageTitle } from '../layout/layout';
import { NftPreview } from '../nfts/preview';
import { Button } from '../shared/components/Button';
import { Ellipsis } from '../shared/components/Ellipsis';
import * as styles from './whois.module.css';

function NamePropRow({
  copy,
  label,
  copyValue,
  children,
}: {
  copy?: boolean;
  label: string;
  copyValue: string | null;
  children?: React.ReactNode;
}) {
  const copyTooltipTargetRef = useRef<HTMLSpanElement | null>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <div className={clsx(styles.row)}>
      <div className={styles.label}>{label}</div>

      {copy && copyValue ? (
        <button
          className={styles.value}
          type="button"
          onClick={() => {
            copyToClipboard(copyValue, { format: 'text/plain' });
            setCopied(true);
          }}
        >
          <span className={styles.valueText}>{children || copyValue}</span>

          <span ref={copyTooltipTargetRef}>
            {copied ? (
              <CheckIcon
                className={clsx(
                  styles.valueCopyIcon,
                  styles.valueCopyIcon_success,
                )}
              />
            ) : (
              <CopyIcon className={styles.valueCopyIcon} />
            )}
          </span>

          <Overlay
            placement="top"
            show={copied}
            target={copyTooltipTargetRef.current}
          >
            {props => <Tooltip {...props}>Copied</Tooltip>}
          </Overlay>
        </button>
      ) : (
        <div className={styles.value}>{copyValue ?? '-'}</div>
      )}
    </div>
  );
}

function NameDisplayBlock({
  children,
  className,
  name,
  isAvailable,
  whoIsResult,
}: {
  children: React.ReactNode;
  className?: string;
  name: string;
  isAvailable: boolean;
  whoIsResult: WhoIsResult;
}) {
  const { userLanguages } = useEntryContext();

  const dateFormat = new Intl.DateTimeFormat(userLanguages, {
    dateStyle: 'short',
  });

  const isRegistered = whoIsResult.status === WhoIsStatus.Registered;

  return (
    <Card className={clsx(styles.cardContainer, 'mb-3', className)}>
      <Card.Body>
        <Container fluid>
          <Row className={clsx('align-items-baseline', styles.header)}>
            <Col className={styles.name}>
              {name}
              {STATIC_DOMAIN}
            </Col>
            <Col
              className={clsx(
                styles.status,
                {
                  [styles.available]: isAvailable,
                },
                'flex justify-content-end',
              )}
            >
              {isAvailable ? 'Available' : 'Unavailable'}
            </Col>
          </Row>

          <hr className={styles.separator} />

          {children && <div className={styles.embedded}>{children}</div>}

          {isAvailable ? (
            <Row className={styles.footer}>
              <Col>
                <div className={clsx(styles.availableText, 'mb-4')}>
                  This domain is available for registration
                </div>

                <Link to={`/auction?${new URLSearchParams({ domain: name })}`}>
                  <Button align="center" as="span" className={styles.action}>
                    Go to auction
                  </Button>
                </Link>
              </Col>
            </Row>
          ) : isRegistered ? (
            <Stack className={styles.info}>
              <NamePropRow label="Parent" copyValue="WAVES" />

              <NamePropRow
                label="Created"
                copyValue={
                  whoIsResult.createdAt == null
                    ? null
                    : dateFormat.format(whoIsResult.createdAt)
                }
              />

              <NamePropRow
                label="Expiration date"
                copyValue={
                  whoIsResult.expiresAt == null
                    ? null
                    : dateFormat.format(whoIsResult.expiresAt)
                }
              />

              <NamePropRow copy label="Owner" copyValue={whoIsResult.owner}>
                {whoIsResult.owner && <Ellipsis text={whoIsResult.owner} />}
              </NamePropRow>
            </Stack>
          ) : (
            <Row className={styles.footer}>
              <Col>
                <div className={clsx(styles.availableText, 'mb-4')}>
                  Sorry, this domain cannot be registered
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Card.Body>
    </Card>
  );
}

export async function whoisLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const q = url.searchParams.get('q');

  if (q === '') {
    url.searchParams.delete('q');
    throw redirect(url.pathname);
  }

  if (!q) {
    return {
      isAvailable: null,
      whoisResult: null,
    };
  }

  const whoisResult = await wavesDomainsClient.whoIs(`${q}${STATIC_DOMAIN}`);

  const isAvailable =
    whoisResult.status === WhoIsStatus.NotRegistered && q != null
      ? await isNameAvailableOnAuction(q)
      : false;

  return {
    isAvailable,
    whoisResult,
  };
}

type LoaderData = Awaited<ReturnType<typeof whoisLoader>>;

export function whoisMeta({ location }: MetaFunctionArgs) {
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get('q');

  if (!search) {
    return;
  }

  return {
    title: `Whois: ${search}${STATIC_DOMAIN}`,
  };
}

export function WhoisPage() {
  const { isAvailable, whoisResult } = useLoaderData() as LoaderData;
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q') ?? '';
  const [search, setSearch] = useState(q);

  useEffect(() => {
    setSearch(q);
  }, [q]);

  return (
    <>
      <PageTitle>Whois</PageTitle>

      <PageContent>
        <Container>
          <Form className={styles.searchForm}>
            <input
              aria-label="Search"
              autoCapitalize="none"
              className={clsx(styles.searchFormInput, 'form-control')}
              name="q"
              placeholder="Search..."
              type="search"
              value={search}
              onChange={event => {
                setSearch(event.target.value);
              }}
            />

            <div className={styles.searchFormLabel}>
              <div className={styles.searchFormLabelValue}>.waves</div>

              <Button
                className={styles.searchFormBtn}
                type="submit"
                variant="primary"
              >
                <LoupeIcon />
              </Button>
            </div>
          </Form>

          {q && whoisResult && isAvailable != null && (
            <ResponsiveStack
              className={clsx(styles.wrapper, 'mt-4')}
              gap={4}
              direction="horizontal"
            >
              <HideOn query="(max-width: 1224px)">
                <NftPreview name={q} />
              </HideOn>

              <NameDisplayBlock
                name={q}
                isAvailable={isAvailable}
                whoIsResult={whoisResult}
              >
                <HideOn query="(min-width: 1225px)">
                  <NftPreview name={q} />
                </HideOn>
              </NameDisplayBlock>
            </ResponsiveStack>
          )}
        </Container>
      </PageContent>
    </>
  );
}
