import clsx from 'clsx';
import { HideOn } from 'modules/responsive/hideOn';
import { Col, Row } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useNavigation } from 'react-router-dom';

import { Sidebar } from '../shared/components/Sidebar';
import * as styles from './layout.module.css';
import { Navbar } from './navbar';

export function Layout({ children }: { children: React.ReactNode }) {
  const navigation = useNavigation();

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.header, {
          [styles.header_loading]: navigation.state !== 'idle',
        })}
      >
        <Navbar />
      </div>

      <div className={styles.body}>
        <Container fluid>
          <Row>
            <HideOn query="(max-width: 1224px)">
              <Col as="aside" sm={3} className={styles.aside}>
                <Sidebar />
              </Col>
            </HideOn>

            <Col sm={9} className={styles.main}>
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export function PageTitle({ children }: { children: React.ReactNode }) {
  return <h2 className="mb-4">{children}</h2>;
}

export function PageContent({ children }: { children: React.ReactNode }) {
  return <div className={styles.content}>{children}</div>;
}
