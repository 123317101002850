import { Button } from 'shared/components/Button';

import * as styles from './intro.module.css';

interface Props {
  onSignMessageClick: () => void;
}

export function TopUpIntro({ onSignMessageClick }: Props) {
  return (
    <div className={styles.box}>
      <p className={styles.title}>
        To enable depositing, please sign the custom message
      </p>

      <div className={styles.text}>
        <p>
          The crypto-gateways are operated by WX.Network. This dialog acts as a
          front-end service for the gateways. WNS neither provides related
          customer support, nor operates, nor maintains the service. For
          details, see{' '}
          <a
            href="https://waves.exchange/files/Terms_Of_Use_Waves.Exchange.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            WX.Network Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://waves.exchange/deposit_withdraw/faq"
            rel="noopener noreferrer"
            target="_blank"
          >
            WX.Network FAQ
          </a>
          . In case of any processing delays or other issues, please contact{' '}
          <a
            href="https://support.waves.exchange/"
            rel="noopener noreferrer"
            target="_blank"
          >
            WX.Network customer support
          </a>
          .
        </p>
      </div>

      <div className="d-grid gap-2">
        <Button
          align="center"
          size="lg"
          variant="primary"
          onClick={onSignMessageClick}
        >
          Sign message
        </Button>
      </div>
    </div>
  );
}
