import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Notifications } from './notifications';
import { type NotificationItem, NotificationType } from './types';

interface ContextValue {
  show: (message: React.ReactElement | string, type?: NotificationType) => void;
  showError: (message: React.ReactElement | string) => void;
}

const Context = createContext<ContextValue | null>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export function NotificationsProvider({ children }: ProviderProps) {
  const idRef = useRef(0);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const [portalContainerEl, setPortalContainerEl] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    setPortalContainerEl(el);

    return () => {
      el.remove();
    };
  }, []);

  const show = useCallback<ContextValue['show']>(
    (message, type: NotificationType = NotificationType.SUCCESS) => {
      const newNotification: NotificationItem = {
        id: ++idRef.current,
        message,
        type,
      };

      setNotifications(prevState => [newNotification, ...prevState]);
    },
    [],
  );

  const value = useMemo(
    (): ContextValue => ({
      show,
      showError: message => show(message, NotificationType.ERROR),
    }),
    [show],
  );

  const closeNotification = (notificationId: number) => {
    setNotifications(prevState =>
      prevState.filter(notification => notification.id !== notificationId),
    );
  };

  return (
    <Context.Provider value={value}>
      {children}

      {portalContainerEl &&
        createPortal(
          <Notifications
            notifications={notifications}
            onClose={closeNotification}
          />,
          portalContainerEl,
        )}
    </Context.Provider>
  );
}

export function useNotifications() {
  const value = useContext(Context);

  if (!value) {
    throw new Error('You must render NotificationsProvider higher in the tree');
  }

  return value;
}
