import { type ActionFunctionArgs, redirect } from 'react-router-dom';
import { type AppStore } from 'store/types';
import invariant from 'tiny-invariant';
import { disconnectWallet } from 'wallet/redux';

export function createLogoutAction(store: AppStore) {
  return async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const next = formData.get('next') ?? '/';
    invariant(typeof next === 'string');

    await store.dispatch(disconnectWallet());
    return redirect(next);
  };
}
