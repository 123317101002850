// extracted by mini-css-extract-plugin
export var action = "jAAB31VEltW94exfKjNG";
export var available = "Wzx430JbvxqEJvJu2ToP";
export var availableText = "eUS589xxkXZURLJG6QZ0";
export var cardContainer = "YhvwR_bdUNKaoaxb5gjf";
export var embedded = "H08b4mwg2D3YRIQgTtK3";
export var footer = "Zkw0n21808NRyMK6teaw";
export var header = "qjVk2p_wmwfLOs_Y1cXH";
export var info = "n5yd4mquOxCzw3Gq8Wbf";
export var label = "wHwayAURy0MFrmtMDLJ1";
export var name = "ulixTNssSmmZB4ZwSq_y";
export var placeholder = "eZRtFCnjbCX8Q68Yjh_g";
export var row = "_wK7SkHmIPhVNNzkXSeP";
export var searchForm = "_VnQIWfkp1ks2SxM4ruh";
export var searchFormBtn = "vASvUEbmjysckKvfOPtN";
export var searchFormInput = "H3AlprLKQ8jPvXM7f77Q";
export var searchFormLabel = "fctsiUi5POcbeALWLlhY";
export var searchFormLabelValue = "sRyyDuHixAz9JA5xr6TP";
export var separator = "uPOesuylgZRmvGslHVfB";
export var status = "TVtk0GpemOiWp46W61I0";
export var value = "i5ee9lWfo8Q5g2P7zZiU";
export var valueCopyIcon = "nX_IyQOeVIqouDtzUaef";
export var valueCopyIcon_success = "fj4gnnL1YlEK76fD7PpY";
export var valueText = "SO9r4JynDmPlKbrAN_SN";
export var wrapper = "ncg5M8WJ1kW67WdRJqfO";