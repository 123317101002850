import { type MetaDescriptor } from '_core/meta';
import { ArrowRightIcon } from 'icons/arrowRight';
import { PageContent, PageTitle } from 'layout/layout';
import {
  isErrorLike,
  isKeeperInvalidNetworkError,
  isKeeperNoAccountsError,
  isKeeperNotInstalledError,
  isMetamaskNotInstalledError,
  isUserRejectionError,
} from 'modules/errors/utils';
import { useNotifications } from 'modules/notifications/context';
import { useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NETWORK } from 'shared/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { connectWallet } from 'wallet/redux';
import { type WalletProvider } from 'wallet/types';

import * as styles from './connectWallet.module.css';

export const connectWalletMeta: MetaDescriptor = {
  title: 'Connect your wallet',
};

interface WalletButtonProps {
  beta?: boolean;
  description?: string;
  logoSrc: string;
  name: string;
  onClick: () => void;
}

function WalletButton({
  beta,
  description,
  logoSrc,
  name,
  onClick,
}: WalletButtonProps) {
  return (
    <button className={styles.item} type="button" onClick={onClick}>
      <div className={styles.logo}>
        <img src={logoSrc} alt="" width={32} height={32} />
      </div>

      <div className={styles.text}>
        <div className={styles.name}>
          {name} {beta && <Badge bg="secondary">Beta</Badge>}
        </div>

        {description && <div className={styles.description}>{description}</div>}
      </div>

      <ArrowRightIcon className={styles.arrow} />
    </button>
  );
}

export function ConnectWalletPage() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const notifications = useNotifications();
  const dispatch = useAppDispatch();
  const wallet = useAppSelector(state => state.wallet.active);

  const next = searchParams.get('next') || '/';

  useEffect(() => {
    if (!wallet) return;

    navigate(next, { replace: true });
  }, [navigate, next, wallet]);

  async function handleConnectRequest(provider: WalletProvider) {
    try {
      await dispatch(connectWallet(provider));
    } catch (err) {
      if (isUserRejectionError(err)) {
        return;
      }

      const networkUsed = {
        mainnet: 'Mainnet',
        testnet: 'Testnet',
      }[NETWORK];

      if (isKeeperInvalidNetworkError(err)) {
        notifications.showError(
          `You should switch Keeper Wallet network to ${networkUsed} to continue`,
        );
      } else if (isKeeperNotInstalledError(err)) {
        notifications.showError(
          'It seems you do not have Keeper Wallet installed',
        );
      } else if (isKeeperNoAccountsError(err)) {
        notifications.showError(
          `You should have an account in Keeper Wallet's ${networkUsed} network to continue`,
        );
      } else if (isMetamaskNotInstalledError(err)) {
        notifications.showError('It seems you do not have Metamask installed');
      } else {
        notifications.showError(
          isErrorLike(err) ? (
            <pre style={{ whiteSpace: 'pre-wrap' }}>{err.message}</pre>
          ) : (
            'Unexpected error'
          ),
        );
      }
    }
  }

  const showHeader = searchParams.get('showHeader') === 'true';

  return (
    <>
      <PageTitle>Connect your wallet</PageTitle>

      <PageContent>
        <div>
          {showHeader && (
            <div className={styles.header}>
              <p className={styles.headerText}>
                To work with this section you must connect your wallet
              </p>

              <img
                className={styles.headerImage}
                width={160}
                height={95}
                src={new URL('./wallet.webp', import.meta.url).pathname}
                alt=""
              />
            </div>
          )}

          <div className={styles.wallets}>
            <WalletButton
              name="Metamask"
              logoSrc={new URL('./metamask.svg', import.meta.url).pathname}
              onClick={() => handleConnectRequest('metamask')}
            />

            <WalletButton
              name="Keeper Wallet"
              logoSrc={new URL('./keeper.svg', import.meta.url).pathname}
              onClick={() => handleConnectRequest('keeper')}
            />

            <WalletButton
              beta
              name="Keeper Mobile"
              logoSrc={new URL('./keeperMobile.svg', import.meta.url).pathname}
              onClick={() => handleConnectRequest('keeperMobile')}
            />

            <WalletButton
              name="Seed or key"
              description="Connect with Waves Exchange"
              logoSrc={new URL('./seed.svg', import.meta.url).pathname}
              onClick={() => handleConnectRequest('web')}
            />

            <WalletButton
              name="Email"
              description="Connect with Waves Exchange"
              logoSrc={new URL('./email.svg', import.meta.url).pathname}
              onClick={() => handleConnectRequest('cloud')}
            />

            <WalletButton
              name="Ledger"
              logoSrc={new URL('./ledger.svg', import.meta.url).pathname}
              onClick={() => handleConnectRequest('ledger')}
            />
          </div>
        </div>
      </PageContent>
    </>
  );
}
