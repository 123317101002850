import { type MetaDescriptor } from '_core/meta';
import { startPolling } from '_core/polling';
import { MobileAuctionStatus } from 'auction/mobileStatus';
import { BidForm } from 'bids/form';
import { BidsList } from 'bids/list';
import {
  type BidDataEntry,
  DataEntry,
  type RevealDataEntry,
  type TopDataEntry,
} from 'bids/types';
import { PageContent, PageTitle } from 'layout/layout';
import { HideOn } from 'modules/responsive/hideOn';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { type LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { NETWORK_CONFIG } from 'shared/config';
import { type AppStore } from 'store/types';
import { array } from 'superstruct';
import invariant from 'tiny-invariant';
import { requireWallet } from 'wallet/requireWallet';
import { type Wallet } from 'wallet/types';

async function fetchBidDataEntries({
  abortSignal,
  wallet,
}: {
  abortSignal: AbortSignal;
  wallet: Wallet;
}) {
  const url = new URL(
    `/addresses/data/${NETWORK_CONFIG.auctionAddress}`,
    NETWORK_CONFIG.nodeBaseUrl,
  );

  url.searchParams.set('matches', `^(Top|Bid_.+_${wallet.address}|Reveal)_.+`);

  const response = await fetch(url, {
    signal: abortSignal,
    headers: {
      accept: 'application/json; large-significand-format=string',
    },
  });

  if (!response.ok) {
    throw response;
  }

  const bidDataEntries: Partial<Record<string, BidDataEntry>> = {};
  const revealDataEntries: Partial<Record<string, RevealDataEntry>> = {};
  const topDataEntries: Partial<Record<string, TopDataEntry>> = {};
  for (const entry of array(DataEntry).create(await response.json())) {
    const [type] = entry.key.split('_');
    invariant(type === 'Bid' || type === 'Reveal' || type === 'Top');

    switch (type) {
      case 'Bid': {
        invariant(entry.type === 'integer');
        const [, auctionId, address, hash] = entry.key.split('_');
        const deposit = entry.value;

        bidDataEntries[entry.key] = {
          address,
          auctionId: Number(auctionId),
          deposit,
          hash,
        };
        break;
      }
      case 'Reveal': {
        invariant(entry.type === 'string');
        const [, auctionId, address, hash] = entry.key.split('_');
        const [deposit, amount, name] = entry.value.split(',');

        revealDataEntries[entry.key] = {
          address,
          amount,
          auctionId: Number(auctionId),
          deposit,
          hash,
          name,
        };
        break;
      }
      case 'Top': {
        invariant(entry.type === 'string');
        const [, name] = entry.key.split('_');
        const [revealKey, price] = entry.value.split(',');

        topDataEntries[entry.key] = {
          name,
          price,
          revealKey,
        };
        break;
      }
    }
  }

  return {
    bidDataEntries,
    revealDataEntries,
    topDataEntries,
  };
}

export function createAuctionLoader(store: AppStore) {
  return async ({ request }: LoaderFunctionArgs) => {
    const wallet = requireWallet(store, request);

    const { bidDataEntries, revealDataEntries, topDataEntries } =
      await fetchBidDataEntries({ abortSignal: request.signal, wallet });

    return {
      bidDataEntries,
      revealDataEntries,
      topDataEntries,
      wallet,
    };
  };
}

type LoaderData = Awaited<ReturnType<ReturnType<typeof createAuctionLoader>>>;

export const auctionMeta: MetaDescriptor = {
  title: 'Auction',
};

export function AuctionPage() {
  const { wallet, ...otherLoaderData } = useLoaderData() as LoaderData;

  const [{ bidDataEntries, revealDataEntries, topDataEntries }, setData] =
    useState(otherLoaderData);

  useEffect(
    () =>
      startPolling(5000, abortSignal =>
        fetchBidDataEntries({ abortSignal, wallet }).then(setData),
      ),
    [wallet],
  );

  return (
    <>
      <HideOn query="(min-width: 1225px)">
        <div className="mb-4">
          <MobileAuctionStatus />
        </div>
      </HideOn>

      <PageTitle>Name`s bid</PageTitle>

      <PageContent>
        <BidForm wallet={wallet} />

        <Container className="mt-5">
          <BidsList
            bidDataEntries={bidDataEntries}
            revealDataEntries={revealDataEntries}
            topDataEntries={topDataEntries}
          />
        </Container>
      </PageContent>
    </>
  );
}
