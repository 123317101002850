import { NftPreview } from 'nfts/preview';
import { useState } from 'react';
import { Modal, Stack } from 'react-bootstrap';
import { Button } from 'shared/components/Button';

import * as styles from './previewButton.module.css';

interface Props {
  name: string;
}

export function PreviewNftButton({ name }: Props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        className={styles.button}
        align="center"
        variant="outline"
        onClick={() => {
          setShow(true);
        }}
      >
        Preview name&apos;s NFT
      </Button>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Body>
          <NftPreview name={name} />
        </Modal.Body>

        <Modal.Footer>
          <Stack>
            <Button
              align="center"
              size="lg"
              variant="link"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
}
