import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import invariant from 'tiny-invariant';

type ContextType = () => number;

const UniqIdContext = createContext<ContextType | null>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export function UniqIdProvider({ children }: ProviderProps) {
  const nextIdRef = useRef(1);

  return (
    <UniqIdContext.Provider value={useCallback(() => nextIdRef.current++, [])}>
      {children}
    </UniqIdContext.Provider>
  );
}

export function useUniqId() {
  const createId = useContext(UniqIdContext);
  invariant(createId, 'You must render UniqIdProvider higher in the tree');

  const [uniqId] = useState(() => createId());

  return uniqId;
}
