import { ScrollRestoration } from 'react-router-dom';
import { GOOGLE_ANALYTICS_ID, INTERCOM_ID, NETWORK } from 'shared/config';
import { WalletSwitchModal } from 'wallet/switchModal';

import { Meta } from './_core/meta';
import { BrowserGlobal } from './browserGlobal';
import { useEntryContext } from './entry';

interface Props {
  children: React.ReactNode;
}

export function Document({ children }: Props) {
  const { css, js, initialReduxState, nonce, routerState } = useEntryContext();

  return (
    <html lang="en" suppressHydrationWarning>
      <head suppressHydrationWarning>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <Meta />

        <link rel="preconnect" href="https://fonts.googleapis.com" />

        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />

        {css.map(href => (
          <link key={href} rel="stylesheet" href={href} />
        ))}

        {js.map(src => (
          <script
            key={src}
            defer
            nonce={nonce}
            src={src}
            suppressHydrationWarning
          />
        ))}

        {GOOGLE_ANALYTICS_ID && (
          <script
            async
            nonce={nonce}
            src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
            suppressHydrationWarning
          />
        )}

        {INTERCOM_ID && (
          <script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `var APP_ID = "${INTERCOM_ID}";window.intercomSettings = {app_id: APP_ID};(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
            }}
            nonce={nonce}
            suppressHydrationWarning
          />
        )}
      </head>

      <body suppressHydrationWarning>
        {children}

        <WalletSwitchModal />

        {!__NODE__ && <ScrollRestoration />}

        <BrowserGlobal
          name="__ENTRY_CONTEXT__"
          nonce={nonce}
          value={{ css, js, nonce, routerState }}
        />

        <BrowserGlobal
          name="__GOOGLE_ANALYTICS_ID__"
          nonce={nonce}
          value={GOOGLE_ANALYTICS_ID}
        />

        <BrowserGlobal
          name="__INTERCOM_ID__"
          nonce={nonce}
          value={INTERCOM_ID}
        />

        <BrowserGlobal name="__NETWORK__" nonce={nonce} value={NETWORK} />

        <BrowserGlobal
          name="__INITIAL_REDUX_STATE__"
          nonce={nonce}
          value={initialReduxState}
        />
      </body>
    </html>
  );
}
