import { combineReducers } from '@reduxjs/toolkit';
import auction from 'auction/redux';
import balances from 'balances/redux';
import bids from 'bids/redux';
import assets from 'modules/assets/redux';
import wallet from 'wallet/redux';

export const reducer = combineReducers({
  assets,
  auction,
  balances,
  bids,
  wallet,
});
