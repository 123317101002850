import type { Canvas, Image } from 'canvas';

type RenderingContext2D = ReturnType<Canvas['getContext']>;

export const NFT_CANVAS_WIDTH = 500;
export const NFT_CANVAS_HEIGHT = 500;

const FONT_SIZE_MAX = 337;
const FONT_SIZE_MIN = 41;
const LINE_HEIGHT_RATIO = 1.25;
const FONT_SIZE_THRESHOLD = 1;

const TEXT_BOX_TOP = 44;
const TEXT_BOX_LEFT = 45;
const TEXT_BOX_WIDTH = 411;
const TEXT_BOX_HEIGHT = 327;

function getFont(size: number) {
  return `bold ${size}px Space Grotesk`;
}

function getLineHeight(fontSize: number) {
  return fontSize * LINE_HEIGHT_RATIO;
}

function getTextWidth(
  ctx: RenderingContext2D,
  lines: string[],
  fontSize: number,
) {
  ctx.save();
  ctx.font = getFont(fontSize);

  const maxWidth = lines.reduce((max, line) => {
    const width = ctx.measureText(line).width;

    return width > max ? width : max;
  }, 0);

  ctx.restore();

  return maxWidth;
}

function getTextHeight(lines: string[], fontSize: number) {
  return getLineHeight(fontSize) * lines.length;
}

interface FitTextResult {
  fontSize: number;
  lines: string[];
}

function fitText(ctx: RenderingContext2D, text: string): FitTextResult {
  const lines = [text];

  if (getTextWidth(ctx, lines, FONT_SIZE_MAX) <= TEXT_BOX_WIDTH) {
    return {
      fontSize: FONT_SIZE_MAX,
      lines,
    };
  }

  if (getTextWidth(ctx, lines, FONT_SIZE_MIN) <= TEXT_BOX_WIDTH) {
    let low = FONT_SIZE_MIN;
    let high = FONT_SIZE_MAX;
    let middle = (low + high) / 2;

    while (low + FONT_SIZE_THRESHOLD < high) {
      middle = (low + high) / 2;
      const textWidth = getTextWidth(ctx, lines, middle);

      if (textWidth > TEXT_BOX_WIDTH) {
        high = middle;
      } else {
        low = middle;
      }
    }

    return {
      fontSize: middle,
      lines,
    };
  }

  let textWidth: number;
  do {
    const lineCount = lines.length + 1;
    const lineLength = Math.ceil(text.length / lineCount);

    lines.splice(0);
    for (let i = 0; i < lineCount; i++) {
      lines.push(text.slice(i * lineLength, (i + 1) * lineLength));
    }

    textWidth = getTextWidth(ctx, lines, FONT_SIZE_MIN);
  } while (textWidth > TEXT_BOX_WIDTH);

  let low = FONT_SIZE_MIN;
  let high = FONT_SIZE_MAX;
  let middle = (low + high) / 2;

  while (low + FONT_SIZE_THRESHOLD < high) {
    middle = (low + high) / 2;
    const textHeight = getTextHeight(lines, middle);

    if (
      getTextWidth(ctx, lines, middle) > TEXT_BOX_WIDTH ||
      textHeight > TEXT_BOX_HEIGHT
    ) {
      high = middle;
    } else {
      low = middle;
    }
  }

  return {
    fontSize: middle,
    lines,
  };
}

export async function renderNftPicture(
  canvas: Canvas | HTMLCanvasElement,
  bgImg: Image,
  name: string,
) {
  if (
    canvas.width !== NFT_CANVAS_WIDTH ||
    canvas.height !== NFT_CANVAS_HEIGHT
  ) {
    throw new Error(
      `Canvas dimensions must be ${NFT_CANVAS_WIDTH}x${NFT_CANVAS_HEIGHT}`,
    );
  }

  const ctx = (canvas as Canvas).getContext('2d');
  ctx.drawImage(bgImg, 0, 0);

  ctx.save();
  ctx.rect(TEXT_BOX_LEFT, TEXT_BOX_TOP, TEXT_BOX_WIDTH, TEXT_BOX_HEIGHT);
  ctx.clip();
  ctx.fillStyle = '#fff';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';

  const { fontSize, lines } = fitText(ctx, name);
  ctx.font = getFont(fontSize);

  const lineHeight = getLineHeight(fontSize);
  const textHeight = getTextHeight(lines, fontSize);

  const textStart =
    TEXT_BOX_TOP +
    Math.max(0, TEXT_BOX_HEIGHT / 2 - textHeight / 2) +
    lineHeight / 2;

  const x = NFT_CANVAS_WIDTH / 2;

  for (let i = 0; i < lines.length; i++) {
    ctx.fillText(lines[i], x, textStart + i * lineHeight);
  }

  ctx.restore();
}
