export function ArrowRightIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
    >
      <path d="M4.61514 13L3.50011 11.8632L8.26847 7L3.5 2.13683L4.61503 1L10.5 7.00006L4.61514 13Z" />
    </svg>
  );
}
