import { BigNumber } from '@waves/bignumber';
import { type Asset, Money } from '@waves/data-entities';
import { type AuctionData } from 'auction/redux';
import clsx from 'clsx';
import { createAssetFromDetails } from 'modules/assets/utils';
import { isErrorLike, isUserRejectionError } from 'modules/errors/utils';
import { useNotifications } from 'modules/notifications/context';
import { useEffect, useRef, useState } from 'react';
import { NETWORK_CONFIG } from 'shared/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import invariant from 'tiny-invariant';
import { invoke } from 'wallet/redux';

import { AuctionTimer } from '../auction/timer';
import { NftCanvas } from '../nfts/canvas';
import * as styles from './card.module.css';
import { STATIC_DOMAIN } from './constants';
import { type Bid, type PrivateBidData, type RevealedBid } from './types';

function Root({
  allRevealdBids,
  bid,
  children,
  wavesAsset,
}: {
  allRevealdBids: RevealedBid[] | undefined;
  bid: Bid;
  children: React.ReactNode;
  wavesAsset: Asset;
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  const sortedBids = allRevealdBids?.sort((bidA, bidB) => {
    const a = new BigNumber(bidA.amount);
    const b = new BigNumber(bidB.amount);

    return a.gt(b) ? -1 : a.lt(b) ? 1 : 0;
  });

  const currentBidIndex = sortedBids?.findIndex(b => b.id === bid.id);
  invariant(currentBidIndex != null);
  const place = currentBidIndex + 1;

  const bidStatsBidsBodyRef = useRef<HTMLTableSectionElement | null>(null);

  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const bidStatsBidsBody = bidStatsBidsBodyRef.current;

    if (!bidStatsBidsBody) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setPageCount(
        Math.ceil(bidStatsBidsBody.scrollWidth / bidStatsBidsBody.clientWidth),
      );
    });

    resizeObserver.observe(bidStatsBidsBody);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  return (
    <div
      className={clsx(styles.root, {
        [styles.root_isFlipped]: isFlipped,
      })}
    >
      <div className={styles.rootInner}>
        <div className={styles.rootFront}>
          {children}

          {sortedBids && sortedBids.length !== 0 && (
            <>
              <div
                className={clsx(styles.placeBadge, {
                  [styles.placeBadge_winner]: place === 1,
                  [styles.placeBadge_loser]: place !== 1,
                })}
              >
                {place}
              </div>

              <button
                className={styles.statsButton}
                type="button"
                onClick={() => {
                  setIsFlipped(true);
                }}
              >
                <span className={styles.statsButtonTextWrapper}>
                  <span className={styles.statsButtonText}>
                    Name Statistics
                  </span>
                </span>
              </button>
            </>
          )}
        </div>

        <div className={styles.rootBack}>
          <h2 className={styles.bidStatsTitle}>Bid statistics</h2>

          {sortedBids && sortedBids.length !== 0 && (
            <>
              <div className={styles.bidStatsSummary}>
                <table className={styles.bidStatsSummaryTable}>
                  <thead>
                    <tr>
                      <th
                        className={clsx(
                          styles.bidStatsSummaryCell,
                          styles.bidStatsSummaryCell_th,
                        )}
                      >
                        Bids
                      </th>

                      <th
                        className={clsx(
                          styles.bidStatsSummaryCell,
                          styles.bidStatsSummaryCell_th,
                          styles.bidStatsSummaryCell_right,
                        )}
                      >
                        Total amount
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td
                        className={clsx(
                          styles.bidStatsSummaryCell,
                          styles.bidStatsSummaryCell_td,
                        )}
                      >
                        {sortedBids.length}
                      </td>

                      <td
                        className={clsx(
                          styles.bidStatsSummaryCell,
                          styles.bidStatsSummaryCell_td,
                          styles.bidStatsSummaryCell_right,
                        )}
                      >
                        {Money.fromCoins(
                          sortedBids.length
                            ? BigNumber.sum(...sortedBids.map(b => b.amount))
                            : new BigNumber(0),
                          wavesAsset,
                        ).toFormat(wavesAsset.precision)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className={styles.bidStatsBids}>
                <table className={styles.bidStatsBidsTable}>
                  <colgroup>
                    <col />
                    <col width="100%" />
                  </colgroup>

                  <thead className={styles.bidStatsBidsHead}>
                    <tr className={styles.bidStatsBidsHeadRow}>
                      <th
                        className={clsx(
                          styles.bidStatsBidsCell,
                          styles.bidStatsBidsCell_th,
                        )}
                      >
                        Place
                      </th>

                      <th
                        className={clsx(
                          styles.bidStatsBidsCell,
                          styles.bidStatsBidsCell_th,
                          styles.bidStatsBidsCell_right,
                        )}
                      >
                        Bid amount (WAVES)
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    ref={bidStatsBidsBodyRef}
                    className={styles.bidStatsBidsBody}
                    onScroll={event => {
                      setCurrentPage(
                        Math.round(
                          event.currentTarget.scrollLeft /
                            event.currentTarget.clientWidth,
                        ),
                      );
                    }}
                  >
                    {sortedBids.map((b, index) => (
                      <tr
                        key={b.id}
                        className={clsx(
                          styles.bidStatsBidsBodyRow,
                          index === currentBidIndex && {
                            [styles.bidStatsBidsBodyRow_currentWinner]:
                              index === 0,
                            [styles.bidStatsBidsBodyRow_currentLoser]:
                              index !== 0,
                          },
                        )}
                      >
                        <td
                          className={clsx(
                            styles.bidStatsBidsCell,
                            styles.bidStatsBidsCell_td,
                            styles.bidStatsBidsCell_place,
                          )}
                        >
                          {index === 0 ? '1st' : index + 1}
                        </td>

                        <td
                          className={clsx(
                            styles.bidStatsBidsCell,
                            styles.bidStatsBidsCell_td,
                            styles.bidStatsBidsCell_right,
                            styles.bidStatsBidsCell_numeric,
                          )}
                        >
                          {Money.fromCoins(b.amount, wavesAsset).toFormat(
                            wavesAsset.precision,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {pageCount > 1 && (
                <ol className={styles.bidStatsPagination}>
                  {Array.from(Array(pageCount), (_, page) => (
                    <li key={page}>
                      <button
                        className={clsx(styles.bidStatsPaginationItem, {
                          [styles.bidStatsPaginationItem_active]:
                            page === currentPage,
                        })}
                        onClick={() => {
                          const bidStatsBidsBody = bidStatsBidsBodyRef.current;

                          if (!bidStatsBidsBody) {
                            return;
                          }

                          bidStatsBidsBody.scrollTo({
                            behavior: 'smooth',
                            left: page * bidStatsBidsBody.clientWidth,
                          });
                        }}
                      />
                    </li>
                  ))}
                </ol>
              )}
            </>
          )}

          <button
            className={styles.bidStatsHideButton}
            type="button"
            onClick={() => {
              setIsFlipped(false);
            }}
          >
            Hide
          </button>
        </div>
      </div>
    </div>
  );
}

function Main({ children }: { children: React.ReactNode }) {
  return <div className={styles.main}>{children}</div>;
}

function NftPreview({
  isBlurred,
  name,
}: {
  isBlurred?: boolean;
  name: string;
}) {
  return (
    <div className={styles.nftPreviewWrapper}>
      <NftCanvas
        className={clsx(styles.nftPreview, {
          [styles.nftPreview_blurred]: isBlurred,
        })}
        name={name}
      />
    </div>
  );
}

function Name({ value }: { value: string }) {
  const fullName = `${value}${STATIC_DOMAIN}`;

  return (
    <div className={styles.name} title={fullName}>
      {fullName}
    </div>
  );
}

function Badge({
  as: As = 'div',
  children,
  theme,
}: {
  as?: React.ElementType<{ className?: string; children?: React.ReactNode }>;
  children: React.ReactNode;
  theme: 'warning' | 'success' | 'fail' | 'muted';
}) {
  return (
    <As
      className={clsx(
        styles.badge,
        {
          warning: styles.badge_theme_warning,
          success: styles.badge_theme_success,
          fail: styles.badge_theme_fail,
          muted: styles.badge_theme_muted,
        }[theme],
      )}
    >
      {children}
    </As>
  );
}

function BidProps({ children }: { children: React.ReactNode }) {
  return <dl className={styles.bidProps}>{children}</dl>;
}

function BidPropsItem({
  label,
  labelBadge,
  value,
}: {
  label: string;
  labelBadge?: string;
  value: string;
}) {
  return (
    <>
      <dt className={styles.bidPropsLabel}>
        {label}
        {labelBadge && (
          <>
            {' '}
            <Badge as="span" theme="success">
              {labelBadge}
            </Badge>
          </>
        )}
      </dt>

      <dd className={styles.bidPropsValue}>{value}</dd>
    </>
  );
}

function Footer({ children }: { children: React.ReactNode }) {
  return <div className={styles.footer}>{children}</div>;
}

function CallToAction({
  description,
  timerUntil,
}: {
  description: string;
  timerUntil?: number;
}) {
  return (
    <div className={styles.bidCallToAction}>
      <div className={styles.bidCallToActionDescription}>{description}</div>

      {timerUntil != null && (
        <AuctionTimer
          className={styles.bidCallToActionTimer}
          until={timerUntil}
        />
      )}
    </div>
  );
}

function ActionButton({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <div className={styles.bidAction}>
      <button
        className={styles.bidActionButton}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

interface Props {
  allRevealdBids: RevealedBid[] | undefined;
  auctionData: AuctionData;
  bid: Bid;
  privateData: PrivateBidData | undefined;
}

export function BidCard({
  allRevealdBids,
  auctionData,
  bid,
  privateData,
}: Props) {
  const notifications = useNotifications();
  const assets = useAppSelector(state => state.assets);
  const dispatch = useAppDispatch();

  const wavesAsset = createAssetFromDetails(assets.WAVES);

  if (!bid.isRevealed) {
    invariant(privateData);

    if (bid.auctionId < auctionData.auctionId) {
      return (
        <Root allRevealdBids={allRevealdBids} bid={bid} wavesAsset={wavesAsset}>
          <Main>
            <NftPreview isBlurred name={privateData.name} />
            <Name value={privateData.name} />

            <BidProps>
              <BidPropsItem
                label="Your bid"
                value={Money.fromCoins(privateData.amount, wavesAsset).toFormat(
                  assets.WAVES.decimals,
                )}
              />

              <BidPropsItem
                label="Deposit"
                value={Money.fromCoins(bid.deposit, wavesAsset).toFormat(
                  assets.WAVES.decimals,
                )}
              />
            </BidProps>

            <Badge theme="muted">This is an expired bid</Badge>
          </Main>

          <Footer>
            <CallToAction description="Bid is expired and can't be Revealed" />
          </Footer>
        </Root>
      );
    }

    return (
      <Root allRevealdBids={allRevealdBids} bid={bid} wavesAsset={wavesAsset}>
        <Main>
          <NftPreview name={privateData.name} />
          <Name value={privateData.name} />

          <BidProps>
            <BidPropsItem
              label="Your bid"
              value={Money.fromCoins(privateData.amount, wavesAsset).toFormat(
                assets.WAVES.decimals,
              )}
            />

            <BidPropsItem
              label="Deposit"
              value={Money.fromCoins(bid.deposit, wavesAsset).toFormat(
                assets.WAVES.decimals,
              )}
            />
          </BidProps>

          <Badge theme="warning">Don&apos;t forget to Reveal bid</Badge>
        </Main>

        <Footer>
          {auctionData.phase === 'BID' ? (
            <CallToAction
              description="Reveal starts in"
              timerUntil={auctionData.revealStart}
            />
          ) : (
            <CallToAction
              description="Reveal ends in"
              timerUntil={auctionData.auctionEnd}
            />
          )}

          <ActionButton
            disabled={auctionData.phase !== 'REVEAL'}
            onClick={async () => {
              /*
            Keeper Mobile cannot accept integer arguments as a string for now.
            Here we need a workaround:
              if the value does not exceed Number.MAX_SAFE_INTEGER,
              return it as Number, otherwise as a String.
            FIXME: after release Keeper-Wallet/Keeper-Wallet-Mobile#184
            */
              const convertBigNumber = (amount: string) => {
                const bn = new BigNumber(amount);

                return bn.lte(Number.MAX_SAFE_INTEGER)
                  ? bn.toNumber()
                  : bn.toFixed();
              };

              try {
                await dispatch(
                  invoke({
                    dApp: NETWORK_CONFIG.auctionAddress,
                    call: {
                      function: 'reveal',
                      args: [
                        {
                          type: 'integer',
                          value: bid.auctionId,
                        },
                        {
                          type: 'string',
                          value: privateData.name,
                        },
                        {
                          type: 'integer',
                          value: convertBigNumber(privateData.amount),
                        },
                        {
                          type: 'binary',
                          value: `base64:${privateData.secret}`,
                        },
                      ],
                    },
                  }),
                );

                notifications.show(`Revealed name bid ${bid.hash}`);
              } catch (err) {
                if (isUserRejectionError(err)) {
                  return;
                }

                // eslint-disable-next-line no-console
                console.error(err);

                notifications.showError(
                  isErrorLike(err) ? (
                    <pre style={{ whiteSpace: 'pre-wrap' }}>{err.message}</pre>
                  ) : (
                    'Unexpected error'
                  ),
                );
              }
            }}
          >
            Reveal bid
          </ActionButton>
        </Footer>
      </Root>
    );
  }

  async function finalize(auctionId: number, hashes: string[]) {
    await dispatch(
      invoke({
        dApp: NETWORK_CONFIG.auctionAddress,
        call: {
          function: 'finalize',
          args: [
            {
              type: 'integer',
              value: auctionId,
            },
            {
              type: 'list',
              value: hashes.map(hash => ({ type: 'string', value: hash })),
            },
          ],
        },
      }),
    );
  }

  if (bid.isTop) {
    return (
      <Root allRevealdBids={allRevealdBids} bid={bid} wavesAsset={wavesAsset}>
        <Main>
          <NftPreview name={bid.name} />
          <Name value={bid.name} />

          <BidProps>
            <BidPropsItem
              label="Your bid"
              labelBadge={
                bid.auctionId < auctionData.auctionId ? 'Winner' : 'Top bid'
              }
              value={Money.fromCoins(bid.amount, wavesAsset).toFormat(
                assets.WAVES.decimals,
              )}
            />

            <BidPropsItem
              label="Deposit"
              value={Money.fromCoins(bid.deposit, wavesAsset).toFormat(
                assets.WAVES.decimals,
              )}
            />
          </BidProps>

          <Badge theme="success">
            You&apos;ll Claim NFT for{' '}
            {Money.fromCoins(bid.price, wavesAsset).toFormat(
              assets.WAVES.decimals,
            )}
          </Badge>
        </Main>

        <Footer>
          {bid.auctionId < auctionData.auctionId ? (
            <CallToAction description="Claim your name" />
          ) : (
            <CallToAction
              description="Auction ends in"
              timerUntil={auctionData.auctionEnd}
            />
          )}

          <ActionButton
            disabled={bid.auctionId === auctionData.auctionId}
            onClick={async () => {
              try {
                await finalize(bid.auctionId, [bid.hash]);
                notifications.show(
                  `Claimed name ${bid.name} by bid ${bid.hash}`,
                );
              } catch (err) {
                if (isUserRejectionError(err)) {
                  return;
                }

                notifications.showError(
                  isErrorLike(err) ? (
                    <pre style={{ whiteSpace: 'pre-wrap' }}>{err.message}</pre>
                  ) : (
                    'Unexpected error'
                  ),
                );
              }
            }}
          >
            Claim NFT
          </ActionButton>
        </Footer>
      </Root>
    );
  }

  return (
    <Root allRevealdBids={allRevealdBids} bid={bid} wavesAsset={wavesAsset}>
      <Main>
        <NftPreview isBlurred name={bid.name} />
        <Name value={bid.name} />

        <BidProps>
          <BidPropsItem
            label="Your bid"
            value={Money.fromCoins(bid.amount, wavesAsset).toFormat(
              assets.WAVES.decimals,
            )}
          />

          <BidPropsItem
            label="Deposit"
            value={Money.fromCoins(bid.deposit, wavesAsset).toFormat(
              assets.WAVES.decimals,
            )}
          />
        </BidProps>

        <Badge theme="fail">Bid missed</Badge>
      </Main>

      <Footer>
        {bid.auctionId < auctionData.auctionId ? (
          <CallToAction description="Refund your bid" />
        ) : (
          <CallToAction
            description="Auction ends in"
            timerUntil={auctionData.auctionEnd}
          />
        )}

        <ActionButton
          disabled={bid.auctionId === auctionData.auctionId}
          onClick={async () => {
            try {
              await finalize(bid.auctionId, [bid.hash]);
            } catch (err) {
              if (isUserRejectionError(err)) {
                return;
              }

              notifications.showError(
                isErrorLike(err) ? (
                  <pre style={{ whiteSpace: 'pre-wrap' }}>{err.message}</pre>
                ) : (
                  'Unexpected error'
                ),
              );
            }
          }}
        >
          Refund bid
        </ActionButton>
      </Footer>
    </Root>
  );
}
