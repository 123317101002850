import { Money } from '@waves/data-entities';
import { base64Encode, randomSeed, stringToBytes } from '@waves/ts-lib-crypto';
import { isNameAvailableOnAuction } from 'auction/api';
import clsx from 'clsx';
import * as saveAs from 'file-saver';
import { createAssetFromDetails } from 'modules/assets/utils';
import { isErrorLike, isUserRejectionError } from 'modules/errors/utils';
import { useNotifications } from 'modules/notifications/context';
import { HideOn } from 'modules/responsive/hideOn';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, Form, Stack } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ResponsiveStack } from 'shared/components/ResponsiveStack/ResponsiveStack';
import { NETWORK_CONFIG } from 'shared/config';
import { useDebouncedValue } from 'shared/hooks/useDebouncedValue';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import invariant from 'tiny-invariant';
import { invoke } from 'wallet/redux';
import { type Wallet } from 'wallet/types';

import { NftPreview } from '../nfts/preview';
import { PreviewNftButton } from '../nfts/previewButton';
import { Cell } from '../shared/components/Cell';
import { BidReadyModal } from '../shared/components/Modals/BidReadyModal';
import * as styles from './form.module.css';
import { addPrivateBidsData } from './redux';
import { type PrivateBidData } from './types';
import {
  makeBidHash,
  makeBidId,
  makeSingleBidBackupFileName,
  privateBidsDataToBlob,
} from './utils';

interface FormValues {
  bid: string;
  deposit: string;
  domain: string;
  secret: string;
}

const initialValues: FormValues = {
  bid: '',
  deposit: '',
  domain: '',
  secret: '',
};

interface Props {
  wallet: Wallet;
}

export function BidForm({ wallet }: Props) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const dispatch = useAppDispatch();
  const auctionPhase = useAppSelector(state => state.auction.data?.phase);

  const minBidAmounts = useAppSelector(
    state => state.auction.data?.minBidAmounts,
  );

  const minNameLength = useAppSelector(
    state => state.auction.data?.minNameLength,
  );

  const wavesAssetDetails = useAppSelector(state => state.assets.WAVES);

  const wavesAsset = useMemo(
    () => createAssetFromDetails(wavesAssetDetails),
    [wavesAssetDetails],
  );

  const initialDomain = searchParams.get('domain');

  const [form, setForm] = useState({
    ...initialValues,
    domain: initialDomain || initialValues.domain,
  });

  const [bidMaskedValue, setBidMaskedValue] = useState(initialValues.bid);

  const [depositMaskedValue, setDepositMaskedValue] = useState(
    initialValues.deposit,
  );

  const debouncedDomain = useDebouncedValue(form.domain, 500);
  const [isAvailableName, setIsAvailableName] = useState<boolean>();

  const [touched, setTouched] = useState<
    Partial<Record<keyof FormValues, boolean>>
  >({
    domain: Boolean(initialDomain),
  });

  const wavesBalanceStr = useAppSelector(state =>
    state.balances.WAVES ? state.balances.WAVES.balance : null,
  );

  const wavesBalance = useMemo(
    () =>
      wavesBalanceStr ? Money.fromCoins(wavesBalanceStr, wavesAsset) : null,
    [wavesAsset, wavesBalanceStr],
  );

  const bidParams = useMemo(
    () => ({
      bid: Money.fromTokens(form.bid, wavesAsset),
      deposit: Money.fromTokens(form.deposit, wavesAsset),
    }),
    [form.bid, form.deposit, wavesAsset],
  );

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setForm(prevState => ({ ...prevState, [name]: value }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;

    setTouched(prevState => ({ ...prevState, [name]: true }));
  };

  const auctionId = useAppSelector(state => state.auction.data?.auctionId);
  const [readyBid, setReadyBid] = useState<PrivateBidData | null>(null);
  const [showBidReadyModal, setShowBidReadyModal] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    try {
      invariant(auctionId != null, 'No auction data');

      const encodedSecret = base64Encode(stringToBytes(form.secret.trim()));

      const hash = makeBidHash({
        name: form.domain,
        amount: bidParams.bid.toCoins(),
        address: wallet.address,
        secret: encodedSecret,
      });

      await dispatch(
        invoke({
          dApp: NETWORK_CONFIG.auctionAddress,
          call: {
            function: 'bid',
            args: [
              {
                type: 'integer',
                value: auctionId,
              },
              {
                type: 'string',
                value: hash,
              },
            ],
          },
          payment: [
            {
              amount: bidParams.deposit.toCoins(),
              assetId: null,
            },
          ],
        }),
      );

      const bid: PrivateBidData = {
        address: wallet.address,
        amount: bidParams.bid.toCoins(),
        auctionId,
        hash,
        id: makeBidId({ auctionId, hash, address: wallet.address }),
        name: form.domain,
        secret: encodedSecret,
        timestamp: Date.now(),
      };

      dispatch(addPrivateBidsData({ [bid.id]: bid }));

      setReadyBid(bid);
      setShowBidReadyModal(true);
      navigate(location.pathname, { replace: true });
      setTouched({});
      setForm(initialValues);
      setBidMaskedValue(initialValues.bid);
      setDepositMaskedValue(initialValues.deposit);
    } catch (err) {
      if (isUserRejectionError(err)) {
        return;
      }

      // eslint-disable-next-line no-console
      console.error(err);

      if (isErrorLike(err)) {
        notifications.showError(
          err.message || 'Could not submit bid. Unexpected error',
        );
      }
    }
  };

  const minimalBid = useMemo(() => {
    if (minBidAmounts == null || minNameLength == null) {
      return null;
    }

    const maxIndex = minBidAmounts.length - 1;
    const index = 7 - form.domain.length;

    return index > maxIndex
      ? null
      : Money.fromCoins(minBidAmounts[Math.max(0, index)], wavesAsset);
  }, [form.domain.length, minBidAmounts, minNameLength, wavesAsset]);

  const errors = useMemo(() => {
    const newErrors: Partial<Record<keyof FormValues | 'form', string>> = {};
    const MAX_NAME_LENGTH = 63;

    (
      [
        {
          field: 'domain',
          error: `Cannot be shorter than ${minNameLength} characters`,
          isValid: minNameLength == null || form.domain.length >= minNameLength,
        },
        {
          field: 'domain',
          error: `Cannot be longer than ${MAX_NAME_LENGTH} characters`,
          isValid: form.domain.length <= MAX_NAME_LENGTH,
        },
        {
          field: 'domain',
          error: 'Bad format',
          isValid: /^([a-zA-Z0-9]+[-]{1})*[a-zA-Z0-9]+$/g.test(form.domain),
        },
        {
          field: 'bid',
          error: 'Cannot be greater than deposit',
          isValid: bidParams.deposit.gte(bidParams.bid),
        },
        {
          field: 'bid',
          error: 'Bid is greater than balance',
          isValid:
            wavesBalance != null &&
            wavesBalance.getTokens().gte(bidParams.bid.getTokens()),
        },
        {
          field: 'deposit',
          error: 'Deposit is greater than balance',
          isValid:
            wavesBalance != null &&
            wavesBalance.getTokens().gte(bidParams.deposit.getTokens()),
        },
        {
          field: 'bid',
          error: `Cannot be less than ${minimalBid?.toFormat()}`,
          isValid:
            minimalBid == null ||
            bidParams.bid.getTokens().gte(minimalBid.getTokens()),
        },
        {
          field: 'bid',
          error: 'Cannot be zero',
          isValid: bidParams.bid.getTokens().gt(0),
        },
        {
          field: 'deposit',
          error: 'Cannot be zero',
          isValid: bidParams.deposit.getTokens().gt(0),
        },
        {
          field: 'secret',
          error: `Secret should not be empty`,
          isValid: form.secret.length > 0,
        },
        {
          field: 'form',
          error: 'Balance is less then deposit',
          isValid:
            wavesBalance != null &&
            wavesBalance.getTokens().gte(bidParams.deposit.getTokens()),
        },
      ] as const
    ).forEach(({ error, field, isValid }) => {
      if (!isValid) {
        newErrors[field] = error;
      }
    });

    return newErrors;
  }, [
    bidParams.bid,
    bidParams.deposit,
    form.domain,
    form.secret.length,
    minNameLength,
    minimalBid,
    wavesBalance,
  ]);

  useEffect(() => {
    if (!debouncedDomain || errors.domain) {
      setIsAvailableName(undefined);
      return;
    }

    isNameAvailableOnAuction(debouncedDomain)
      .then(setIsAvailableName)
      .catch(err =>
        notifications.showError(
          `Could not check name availability: ${
            isErrorLike(err) ? err.message : 'Unexpected error'
          }`,
        ),
      );
  }, [debouncedDomain, errors.domain, notifications]);

  useEffect(() => {
    if (form.domain) {
      setForm(prevForm => ({
        ...prevForm,
        secret: randomSeed(3),
      }));
    }
  }, [form.domain]);

  const isValid =
    Object.keys(errors).length === 0 &&
    isAvailableName &&
    auctionPhase !== 'REVEAL';

  return (
    <Container>
      <ResponsiveStack gap={4} direction="horizontal">
        <Card className={styles.container}>
          <Card.Body className={styles.cardBody}>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <p className={styles.label}>Fill the required fields</p>
              </Form.Group>

              <Form.Group className={styles.formGroup}>
                <Form.Label htmlFor="Domain">
                  <Cell
                    left="Domain"
                    align="center"
                    right={
                      <>
                        {!errors.domain && !isAvailableName ? (
                          <div className={clsx(styles.formStatus)}>
                            <span className={styles.error}>
                              Name is not available
                            </span>
                          </div>
                        ) : (
                          <>
                            <div>
                              {isValid && (
                                <span
                                  className={clsx(
                                    styles.formStatus,
                                    styles.bid,
                                  )}
                                >
                                  Ready to bid
                                </span>
                              )}

                              {auctionPhase === 'REVEAL' && (
                                <span
                                  className={clsx(
                                    styles.formStatus,
                                    styles.reveal,
                                  )}
                                >
                                  Reveal, cannot bid
                                </span>
                              )}
                            </div>

                            {touched.domain && errors.domain && (
                              <span
                                className={clsx(
                                  styles.formStatus,
                                  styles.error,
                                )}
                              >
                                {errors.domain}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </Form.Label>

                <div className={styles.inputGroup}>
                  <Form.Control
                    autoCapitalize="none"
                    className={styles.withAddon}
                    onInput={handleInput}
                    onBlur={handleBlur}
                    type="string"
                    id="Domain"
                    name="domain"
                    autoComplete="off"
                    value={form.domain}
                  />

                  <span className={clsx(styles.innerLabel, styles.currency)}>
                    .waves
                  </span>
                </div>
              </Form.Group>

              <Form.Group className={styles.formGroup}>
                <Form.Label htmlFor="Bid">
                  <Cell
                    left="Your bid"
                    align="center"
                    right={
                      touched.bid &&
                      errors.bid && (
                        <span className={clsx(styles.formStatus, styles.error)}>
                          {errors.bid}
                        </span>
                      )
                    }
                  />
                </Form.Label>

                <div className={styles.inputGroup}>
                  <IMaskInput
                    autoComplete="off"
                    className={clsx(styles.withAddon, 'form-control')}
                    id="Bid"
                    inputMode="decimal"
                    mapToRadix={['.', ',']}
                    mask={Number}
                    name="bid"
                    radix="."
                    scale={wavesAsset.precision}
                    thousandsSeparator=","
                    value={bidMaskedValue}
                    onAccept={(_, mask) => {
                      setBidMaskedValue(mask.value);

                      setForm(prevState => ({
                        ...prevState,
                        bid: mask.unmaskedValue,
                      }));
                    }}
                    onBlur={handleBlur}
                  />

                  <span className={clsx(styles.innerLabel)}>WAVES</span>

                  {minimalBid && (
                    <span className={styles.helpText}>
                      Min. amount is {minimalBid.toFormat()} WAVES
                    </span>
                  )}
                </div>
              </Form.Group>

              <Form.Group className={styles.formGroup}>
                <Form.Label htmlFor="Deposit">
                  <Cell
                    left="Deposit"
                    align="center"
                    right={
                      touched.deposit &&
                      errors.deposit && (
                        <span className={clsx(styles.formStatus, styles.error)}>
                          {errors.deposit}
                        </span>
                      )
                    }
                  />
                </Form.Label>

                <div className={styles.inputGroup}>
                  <IMaskInput
                    autoComplete="off"
                    className={clsx(styles.withAddon, 'form-control')}
                    id="Deposit"
                    inputMode="decimal"
                    mapToRadix={['.', ',']}
                    mask={Number}
                    name="deposit"
                    radix="."
                    scale={wavesAsset.precision}
                    thousandsSeparator=","
                    value={depositMaskedValue}
                    onAccept={(_, mask) => {
                      setDepositMaskedValue(mask.value);

                      setForm(prevState => ({
                        ...prevState,
                        deposit: mask.unmaskedValue,
                      }));
                    }}
                    onBlur={handleBlur}
                  />

                  <span className={clsx(styles.innerLabel)}>WAVES</span>

                  <span className={styles.helpText}>
                    Must be greater than or equal to bid
                  </span>
                </div>
              </Form.Group>

              <Form.Group className={styles.formGroup}>
                <Form.Label htmlFor="Secret" type="string">
                  <Cell
                    left="Secret"
                    align="center"
                    right={
                      touched.secret &&
                      errors.secret && (
                        <span className={clsx(styles.formStatus, styles.error)}>
                          {errors.secret}
                        </span>
                      )
                    }
                  />
                </Form.Label>

                <Form.Control
                  autoCapitalize="none"
                  id="Secret"
                  onInput={handleInput}
                  onBlur={handleBlur}
                  name="secret"
                  type="text"
                  autoComplete="off"
                  value={form.secret}
                />
              </Form.Group>

              <Stack gap={2} className="mt-3">
                <Cell
                  left={
                    touched.deposit &&
                    errors.form && (
                      <span className={clsx(styles.formStatus, styles.error)}>
                        {errors.form}
                      </span>
                    )
                  }
                  align="center"
                />

                <Button
                  className={styles.action}
                  disabled={!isValid}
                  type="submit"
                  variant="primary"
                >
                  Place your bid
                </Button>
              </Stack>

              {isValid && wavesBalance?.getCoins().gt(0) && (
                <Card className={clsx(styles.warning, 'mt-3')}>
                  <Card.Body>
                    <p>
                      Your bids are present only on the device with which it is
                      made.
                    </p>

                    <p>
                      You can also import and export bids for viewing on other
                      devices.
                    </p>
                  </Card.Body>
                </Card>
              )}

              {(!wavesBalance || wavesBalance.getCoins().eq(0)) && (
                <Card className={clsx(styles.warning, 'mt-3')}>
                  <Card.Body>
                    <p>
                      You have no WAVES token on your balance. Please go to{' '}
                      <Link to="/top-up">Top-up</Link> page to fill up your
                      balance.
                    </p>

                    <p>
                      Or you can use{' '}
                      <a
                        href="https://swap.keeper-wallet.app/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Keeper Swap Widget
                      </a>{' '}
                      to swap tokens on WAVES.
                    </p>
                  </Card.Body>
                </Card>
              )}
            </Form>
          </Card.Body>
        </Card>

        {form.domain && (
          <>
            <HideOn query="(min-width: 1225px)">
              <PreviewNftButton name={form.domain} />
            </HideOn>

            <HideOn query="(max-width: 1224px)">
              <NftPreview name={form.domain} />
            </HideOn>
          </>
        )}
      </ResponsiveStack>

      {readyBid && (
        <BidReadyModal
          name={readyBid.name}
          show={showBidReadyModal}
          onConfirm={() => {
            saveAs(
              privateBidsDataToBlob({ [readyBid.id]: readyBid }),
              makeSingleBidBackupFileName(readyBid),
            );

            setShowBidReadyModal(false);
          }}
          onExited={() => {
            setReadyBid(null);
          }}
        />
      )}
    </Container>
  );
}
