import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { NETWORK_CONFIG } from 'shared/config';
import { type AppThunkAction } from 'store/types';

import { type BalancesItem } from './types';

type State = Partial<Record<string, BalancesItem>>;

const initialState: State = {};

const { actions, reducer } = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    merge(state, action: PayloadAction<Record<string, BalancesItem>>) {
      Object.assign(state, action.payload);
    },
    clear() {
      return initialState;
    },
  },
});

export default reducer;

export function updateWavesBalance({
  abortSignal,
}: {
  abortSignal: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const wallet = state.wallet.active;

    if (!wallet) {
      return;
    }

    const response = await fetch(
      new URL(
        `/addresses/balance/details/${wallet.address}`,
        NETWORK_CONFIG.nodeBaseUrl,
      ),
      {
        signal: abortSignal,
        headers: {
          accept: 'application/json; large-significand-format=string',
        },
      },
    );

    const wavesBalance: {
      address: string;
      regular: string;
      generating: string;
      available: string;
      effective: string;
    } = await response.json();

    dispatch(
      actions.merge({
        WAVES: {
          balance: wavesBalance.available,
          minSponsoredAssetFee: '100000',
          sponsorBalance: wavesBalance.available,
        },
      }),
    );
  };
}

export const { clear: clearWavesBalance } = actions;
