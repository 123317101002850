import { useMemo } from 'react';
import {
  type Location,
  matchRoutes,
  type Params,
  useLocation,
} from 'react-router-dom';

import { useEntryContext } from '../entry';

export interface MetaFunctionArgs<T = unknown> {
  data: T;
  error: unknown;
  params: Params;
  location: Location;
}

export type MetaDescriptor = Partial<{
  title: string;
  [name: string]: string | null | undefined;
}>;

export function Meta() {
  const { routerState, routes } = useEntryContext();
  const location = useLocation();

  const meta = useMemo((): MetaDescriptor | undefined => {
    const matches = matchRoutes(routes, location);
    const { errors, loaderData } = routerState;

    const result = matches?.reduce<MetaDescriptor>((acc, { params, route }) => {
      if (route.meta) {
        let routeMeta: MetaDescriptor | void;

        if (typeof route.meta === 'function') {
          routeMeta = route.meta({
            data: loaderData?.[route.id],
            error: errors?.[route.id],
            location,
            params,
          });
        } else {
          routeMeta = route.meta;
        }

        return Object.assign(acc, routeMeta);
      }

      return acc;
    }, {});

    if (!result) {
      return undefined;
    }

    let { title } = result;
    title = `${result.title ? `${result.title} | ` : ''}Waves Domains`;
    const { title: _, description, ...otherProps } = result;

    return {
      title,
      description,
      'og:title': title,
      'og:description': description,
      ...otherProps,
    };
  }, [routes, location, routerState]);

  if (!meta) {
    return null;
  }

  return (
    <>
      {Object.entries(meta).map(([name, value]) =>
        value == null ? null : name === 'title' ? (
          <title key="title">{value}</title>
        ) : /^(og|music|video|article|book|profile|fb):.+$/.test(name) ? (
          <meta key={name + value} property={name} content={value} />
        ) : (
          <meta key={name + value} name={name} content={value} />
        ),
      )}
    </>
  );
}
