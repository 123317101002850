import clsx from 'clsx';
import { BurgerIcon } from 'icons/burger';
import { LoupeIcon } from 'icons/loupe';
import { MobileCrossIcon } from 'icons/mobileCross';
import { NavbarSearch } from 'layout/search';
import { UserInfo } from 'layout/userInfo';
import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Sidebar } from 'shared/components/Sidebar';
import { SocialLinks } from 'shared/components/SocialLinks/SocialLinks';

import { MobileAuctionStatus } from '../auction/mobileStatus';
import * as styles from './mobileNavbar.module.css';

interface Props {
  className?: string;
}

export function MobileNavbar({ className }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <Navbar bg="light" expand className={clsx(className, styles.navbar)}>
      <div className={styles.inner}>
        {showSearch && (
          <div className={styles.headerSearch}>
            <NavbarSearch autoFocus className={styles.search} />

            <IconButton
              className={styles.close}
              icon={<MobileCrossIcon />}
              onClick={() => {
                setShowSearch(false);
              }}
            />
          </div>
        )}

        {!showSearch && (
          <>
            <IconButton
              className={styles.button}
              icon={<BurgerIcon />}
              onClick={() => {
                setIsMenuOpen(true);
              }}
            />

            <Navbar.Toggle />

            <Link to="/">
              <img
                src={new URL('./logo.svg', import.meta.url).pathname}
                alt="Waves Domains Logo"
                width={142}
                height={14}
              />
            </Link>

            <IconButton
              className={styles.button}
              icon={<LoupeIcon />}
              onClick={() => {
                setShowSearch(true);
              }}
            />
          </>
        )}
      </div>

      <Offcanvas
        aria-labelledby="offcanvasNavbarLabel-expand"
        id="offcanvasNavbar-expand"
        placement="start"
        show={isMenuOpen}
        onHide={() => {
          setIsMenuOpen(false);
        }}
      >
        <Offcanvas.Header className={styles.header} closeButton>
          <Offcanvas.Title
            className={styles.title}
            id="offcanvasNavbarLabel-expand"
          >
            <div className={styles.inner}>
              <Navbar.Toggle />

              <Link to="/">
                <img
                  src={new URL('./logo.svg', import.meta.url).pathname}
                  alt="Waves Domains Logo"
                  width={142}
                  height={14}
                />
              </Link>

              <span />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Stack direction="vertical" gap={4}>
            <NavbarSearch
              onSubmit={() => {
                setIsMenuOpen(false);
              }}
            />

            <MobileAuctionStatus />

            <Sidebar
              showAuction={false}
              showSocial={false}
              onItemClick={() => {
                setIsMenuOpen(false);
              }}
            />

            <UserInfo
              isSideBar
              onConnectWalletClick={() => {
                setIsMenuOpen(false);
              }}
            />

            <SocialLinks className="mt-4" />
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}
