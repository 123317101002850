const BASE_URL = 'https://api.keeper-wallet.app';

export interface KeeperAssetInfoResponseItem {
  id: string;
  ticker: string;
  url: string;
}

export async function fetchKeeperAssetsInfo({
  abortSignal,
}: {
  abortSignal: AbortSignal;
}): Promise<KeeperAssetInfoResponseItem[]> {
  const response = await fetch(new URL('api/v1/assets', BASE_URL), {
    signal: abortSignal,
  });

  return await response.json();
}
