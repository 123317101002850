import clsx from 'clsx';
import { LoupeIcon } from 'icons/loupe';
import { useEffect, useState } from 'react';
import { Form, useSearchParams } from 'react-router-dom';

import * as styles from './search.module.css';

interface Props {
  autoFocus?: boolean;
  className?: string;
  onSubmit?: () => void;
}

export function NavbarSearch({ autoFocus, className, onSubmit }: Props) {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q') ?? '';
  const [search, setSearch] = useState(q);

  useEffect(() => {
    setSearch(q);
  }, [q]);

  return (
    <Form className={clsx(styles.root, className)} onSubmit={onSubmit}>
      <input
        aria-label="Search"
        autoCapitalize="none"
        autoFocus={autoFocus}
        className={clsx(styles.input, 'form-control')}
        name="q"
        placeholder="Search for your name..."
        type="search"
        value={search}
        onChange={event => {
          setSearch(event.target.value);
        }}
      />

      <LoupeIcon className={styles.icon} />
    </Form>
  );
}
