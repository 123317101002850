import copy from 'copy-to-clipboard';
import QrCode from 'qrcode';
import { useEffect, useState } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { Button } from 'shared/components/Button';

import * as styles from './qrModal.module.css';

export function QrModal({
  show,
  address,
  onClose,
}: {
  show: boolean;
  address: string;
  onClose: () => void;
}) {
  const [copiedNotification, setCopiedNotification] = useState(false);
  const [qrSrc, setQrSrc] = useState<string>();

  const qrSize = 200;

  useEffect(() => {
    QrCode.toDataURL(address, {
      errorCorrectionLevel: 'H',
      margin: 1,
      rendererOpts: { quality: 1 },
      scale: 16,
      type: 'image/webp',
      width: qrSize,
    }).then(setQrSrc);
  }, [address]);

  return (
    <Modal dialogClassName={styles.modal} show={show} centered onHide={onClose}>
      <div className={styles.box}>
        <h2 className={styles.header}>Scan this QR code</h2>

        <Form.Group className={styles.addressGroup}>
          <InputGroup>
            <Form.Control
              className={styles.addressControl}
              readOnly
              value={address}
            />

            {copiedNotification ? (
              <InputGroup.Text className={styles.addressCopiedNotification}>
                Copied
              </InputGroup.Text>
            ) : (
              <>
                <Button
                  align="center"
                  variant="primary"
                  onClick={() => {
                    copy(address, { format: 'text/plain' });

                    setCopiedNotification(true);

                    setTimeout(() => {
                      setCopiedNotification(false);
                    }, 2000);
                  }}
                >
                  Copy address
                </Button>
              </>
            )}
          </InputGroup>
        </Form.Group>

        <div className={styles.qrCodeBox}>
          <img
            className={qrSrc ? undefined : 'skeleton-glow'}
            src={qrSrc}
            width={qrSize}
            height={qrSize}
          />
        </div>

        <p className={styles.tooltip}>Use your device camera to scan this QR</p>

        <button className={styles.closeBtn} onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
}
