import { fetchKeeperAssetsInfo } from '_core/keeperApi';
import { type MetaDescriptor } from '_core/meta';
import { PageContent, PageTitle } from 'layout/layout';
import { isErrorLike, isUserRejectionError } from 'modules/errors/utils';
import {
  type DepositAddressesResponse,
  fetchDepositAddresses,
  fetchDepositCurrencies,
  fetchMovementsHistory,
  fetchPlatforms,
  GatewayApiError,
  type MovementsHistoryItem,
} from 'modules/gateways/api';
import { useNotifications } from 'modules/notifications/context';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { type LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { type AppStore } from 'store/types';
import { obtainWxAccessToken, refreshWxAccessToken } from 'wallet/redux';
import { requireWallet } from 'wallet/requireWallet';

import { TopUpForm } from './form';
import { TopUpHistory } from './history';
import { TopUpIntro } from './intro';

export function createTopUpLoader(store: AppStore) {
  return async ({ request }: LoaderFunctionArgs) => {
    const wallet = requireWallet(store, request);

    try {
      const [depositCurrencies, keeperAssetInfo, platforms] = await Promise.all(
        [
          fetchDepositCurrencies({ abortSignal: request.signal }),
          fetchKeeperAssetsInfo({ abortSignal: request.signal }).catch(
            () => null,
          ),
          fetchPlatforms({ abortSignal: request.signal }),
        ],
      );

      return {
        depositCurrencies,
        keeperAssetInfo,
        platforms,
        wallet,
      };
    } catch (err) {
      throw new Response('Could not connect to WX.Network Gateways.', {
        status: 500,
        statusText: 'Internal Server Error',
      });
    }
  };
}

type LoaderData = Awaited<ReturnType<ReturnType<typeof createTopUpLoader>>>;

export const topUpMeta: MetaDescriptor = {
  title: 'Top-up',
};

export function TopUpPage() {
  const { depositCurrencies, keeperAssetInfo, platforms, wallet } =
    useLoaderData() as LoaderData;

  const notifications = useNotifications();
  const dispatch = useAppDispatch();
  const wxAccessToken = useAppSelector(state => state.wallet.wxAccessToken);

  const [currencyId, setCurrencyId] = useState<string | null>(null);
  const [platformId, setPlatformId] = useState<string | null>(null);

  const [depositAddresses, setDepositAddresses] =
    useState<DepositAddressesResponse | null>(null);

  useEffect(() => {
    setDepositAddresses(null);

    if (wxAccessToken == null || currencyId == null || platformId == null) {
      return;
    }

    const updateDepositAddresses = async () => {
      try {
        setDepositAddresses(
          await fetchDepositAddresses({
            accessToken: wxAccessToken.access_token,
            currencyId,
            platformId,
          }),
        );
      } catch (err) {
        if (GatewayApiError.isInvalidTokenError(err)) {
          dispatch(refreshWxAccessToken());
          return;
        }

        throw err;
      }
    };

    updateDepositAddresses();
  }, [currencyId, dispatch, platformId, wxAccessToken]);

  const [movementsHistoryItems, setMovementsHistoryItems] = useState<
    MovementsHistoryItem[] | null
  >(null);

  useEffect(() => {
    if (wxAccessToken == null) {
      return;
    }

    let timeout: ReturnType<typeof setTimeout>;

    const updateMovementsHistory = async () => {
      try {
        setMovementsHistoryItems(
          await fetchMovementsHistory({
            accessToken: wxAccessToken.access_token,
            limit: 5,
          }),
        );

        timeout = setTimeout(updateMovementsHistory, 10000);
      } catch (err) {
        if (GatewayApiError.isInvalidTokenError(err)) {
          dispatch(refreshWxAccessToken());
          return;
        }

        throw err;
      }
    };

    updateMovementsHistory();

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, wxAccessToken]);

  return (
    <>
      <PageTitle>Top-up</PageTitle>

      <PageContent>
        <Container>
          {wxAccessToken ? (
            <>
              <div className="mb-5">
                <TopUpForm
                  currencyId={currencyId}
                  depositAddresses={depositAddresses}
                  depositCurrencies={depositCurrencies}
                  keeperAssetInfo={keeperAssetInfo}
                  platformId={platformId}
                  platforms={platforms}
                  userAddress={wallet.address}
                  onCurrencyChange={newCurrencyId => {
                    setCurrencyId(newCurrencyId);
                    setPlatformId(null);
                  }}
                  onPlatformChange={newPlatformId => {
                    setPlatformId(newPlatformId);
                  }}
                  onReset={() => {
                    setCurrencyId(null);
                    setPlatformId(null);
                  }}
                />
              </div>

              <h2>History</h2>

              <TopUpHistory
                items={movementsHistoryItems}
                platforms={platforms}
              />
            </>
          ) : (
            <TopUpIntro
              onSignMessageClick={async () => {
                try {
                  await dispatch(obtainWxAccessToken());
                } catch (err) {
                  if (isUserRejectionError(err)) {
                    return;
                  }

                  notifications.showError(
                    isErrorLike(err) ? err.message : 'Something went wrong',
                  );
                }
              }}
            />
          )}
        </Container>
      </PageContent>
    </>
  );
}
