const API_BASE_URL = 'https://api.wx.network';

interface GatewayApiErrorJson {
  errors: Array<{
    code: number;
    message: string;
  }>;
}

export class GatewayApiError extends Error {
  json;
  status;

  static isInvalidTokenError(err: unknown) {
    return (
      err instanceof GatewayApiError &&
      err.status === 401 &&
      err.json.errors.some(e => e.code === 10000)
    );
  }

  constructor(status: number, json: GatewayApiErrorJson) {
    super('GatewayApiError');
    this.json = json;
    this.status = status;
  }
}

async function throwGatewayApiErrorIfAny(response: Response) {
  if (response.ok) {
    return;
  }

  const text = await response.text();

  let json: GatewayApiErrorJson;

  try {
    json = JSON.parse(text);
  } catch {
    throw new Error(text);
  }

  throw new GatewayApiError(response.status, json);
}

export interface DepositCurrency {
  type: 'deposit_currency';
  id: string;
  platform_id: string;
  waves_asset_id: string;
  platform_asset_id: string;
  decimals: number;
  status: 'active' | 'inactive';
  allowed_amount: {
    min: number;
    max: number;
  };
  fees: {
    flat: number;
    rate: number;
  };
}

interface DepositCurrenciesResponse {
  type: 'list';
  page_info: {
    has_next_page: boolean;
    last_cursor: string | null;
  };
  items: DepositCurrency[];
}

export async function fetchDepositCurrencies({
  abortSignal,
}: {
  abortSignal: AbortSignal;
}) {
  const url = new URL('/v1/deposit/currencies', API_BASE_URL);
  const response = await fetch(url, { signal: abortSignal });
  await throwGatewayApiErrorIfAny(response);
  const result: DepositCurrenciesResponse = await response.json();

  return result.items;
}

export interface PlatformsItem {
  type: 'platform';
  id: string;
  name: string;
  currencies: string[];
}

interface PlatformsResponse {
  type: 'list';
  page_info: {
    has_next_page: boolean;
    last_cursor: string | null;
  };
  items: PlatformsItem[];
}

export async function fetchPlatforms({
  abortSignal,
}: {
  abortSignal: AbortSignal;
}) {
  const url = new URL('/v1/platforms', API_BASE_URL);
  const response = await fetch(url, { signal: abortSignal });
  await throwGatewayApiErrorIfAny(response);
  const result: PlatformsResponse = await response.json();

  return result.items;
}

export interface DepositAddressesResponse {
  type: 'deposit_addresses';
  currency: DepositCurrency;
  deposit_addresses: string[];
}

export async function fetchDepositAddresses({
  accessToken,
  currencyId,
  platformId,
}: {
  accessToken: string;
  currencyId: string;
  platformId: string;
}) {
  const url = new URL(
    `/v1/deposit/addresses/${currencyId}/${platformId}`,
    API_BASE_URL,
  );

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  await throwGatewayApiErrorIfAny(response);

  const result: DepositAddressesResponse = await response.json();

  return result;
}

export interface MovementsHistoryItem {
  type: 'gateway_movement';
  id: string;
  direction: 'deposit' | 'withdrawal';
  status: 'in_progress' | 'pending_approval' | 'confirmed' | 'rejected';
  source_platform_id: string;
  destination_platform_id: string;
  currency_id: string;
  amount: number;
  fee: number;
  created_at: string;
  updated_at: string;
  recipient_address: string;
  transactions: Array<{
    transaction_id: string;
    platform_id: string;
  }>;
}

interface MovementsHistoryResponse {
  type: 'list';
  page_info: {
    has_next_page: boolean;
    last_cursor: string;
  };
  items: MovementsHistoryItem[];
}

export async function fetchMovementsHistory({
  accessToken,
  limit,
}: {
  accessToken: string;
  limit?: number;
}) {
  const url = new URL('/v1/movements', API_BASE_URL);

  if (limit) {
    url.searchParams.set('limit', String(limit));
  }

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  await throwGatewayApiErrorIfAny(response);

  const result: MovementsHistoryResponse = await response.json();

  return result.items;
}
